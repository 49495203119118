import { ContentType, Method } from "axios-mapper";
import https from "../utils/https";

export const createDepositSetting = (depositSetting) => {
  return https().request("/depositSetting", Method.POST, depositSetting, ContentType.form);
};

export const updateDepositSetting = (depositSetting) => {
  return https().request(`/depositSetting/${depositSetting.id}?_method=PUT`, Method.POST, depositSetting, ContentType.form);
};

export const getDepositSetting = (depositSetting) => {
  return https().request("/depositSetting", Method.GET, depositSetting, ContentType.form);
};

export const insertOrUpdate = (depositSetting) => {
  return https().request("/depositSetting/insertOrUpdate", Method.POST, { depositSetting: JSON.stringify(depositSetting) }, ContentType.form);
};

export const getDepositRequestErrors = () => {
  return https().request("/depositSetting/requestErrors", Method.GET);
}

export const updateAmountSetting = (amountList, siteId, type) => {
  return https().request(`/depositSetting/updateAmountListByType/${siteId}/${type}?_method=PUT`, Method.POST, { amountList: amountList }, ContentType.form);
}
