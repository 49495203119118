export default {
  common: {
    title: 'TF bảng điều khiển',
    logout: 'Đăng xuất',
    welcome_system: 'Chào mừng bạn đến với hệ thống quản lý',
    changePassword: 'Thay đổi mật khẩu',
    status: {
      OPEN: 'Mở',
      CLOSE: 'Đóng',
      TEST: 'Kiểm tra',
    },
  },
  affiliate: {
    commissionModel: {
      NORMAL: 'Bình thường',
      SIMPLE: 'Đơn giản',
    },
    level: {
      JUNIOR_AFFILIATE: 'Người chơi',
      SUB_AFFILIATE: 'Cửa hàng',
      AFFILIATE: 'Chi nhánh',
      SUPER_AFFILIATE: 'Trụ sở chính',
      MASTER_AFFILIATE: 'Đại lý',
    },
    timeType: {
      MONTHLY: 'Hàng tháng',
      WEEKLY: 'Hàng tuần',
    },
    status: {
      APPLY: 'Ứng tuyển',
      NORMAL: 'Bình thường',
      DISABLE: 'Vô hiệu hóa',
    },
    belongType: {
      OFFICIAL: 'Chính thức',
      PACKAGE: 'Gói',
    },
  },
  types: {
    NORMAL: 'Bình thường',
    TEMPORARY: 'Tạm thời',
    PERMANENT: 'Vĩnh viễn',
    gameViolation: 'Vi phạm trò chơi',
    memberRequest: 'Yêu cầu của thành viên',
    others: 'Khác',
    TEST: 'Kiểm tra',
    PROMO_TEST: 'Kiểm tra khuyến mãi',
    AFFILIATE: 'Đối tác liên kết',
    OUTSIDE: 'Bên ngoài',
  },
  reportName: {
    Aff_Month_Report: 'Báo cáo tháng của đối tác',
    Deposit_Record: 'Ghi chép nạp tiền',
    Withdraw_Record: 'Ghi chép rút tiền',
    Privilege_Record: 'Ghi chép đặc quyền',
    Finance_Report: 'Báo cáo tài chính',
    Summary_Record: 'Ghi chép tóm tắt',
    Summary_Active_Record: 'Ghi chép hoạt động tóm tắt',
    Summary_Register_Record: 'Ghi chép đăng ký tóm tắt',
    Summary_Fdp_Record: 'Ghi chép FDP tóm tắt',
    Summary_Withdraw_Record: 'Ghi chép rút tiền tóm tắt',
    Summary_Deposit_Record: 'Ghi chép nạp tiền tóm tắt',
    Summary_Member_Record: 'Ghi chép thành viên tóm tắt',
    Member_Bet_Record: 'Ghi chép cược của thành viên',
    Member_Bet_Money_Change: 'Thay đổi tiền cược của thành viên',
    Daily_Record: 'Ghi chép hàng ngày',
    Platform_Game_Record: 'Ghi chép trò chơi trên nền tảng',
  },
  dateType: {
    depositDate: 'Ngày nạp tiền',
    finishDate: 'Ngày hoàn thành',
    withdrawDate: 'Ngày rút tiền',
    paymentDate: 'Ngày thanh toán',
    regTime: 'Thời gian đăng ký',
    recordTime: 'Thời gian ghi chép',
  },
  depositStatus: {
    SUCCESS: 'Thành công',
    SUPPLEMENT_SUCCESS: 'Bổ sung thành công',
    CLOSED: 'Đóng',
    PENDING: 'Đang chờ',
  },
  withdrawStatus: {
    ALL: 'Tất cả',
    SUCCESS: 'Thành công',
    FAIL: 'Thất bại',
    APPLY: 'Đang ứng tuyển',
    STEP_1: 'Đang kiểm tra',
    STEP_2: 'Đang chờ thanh toán',
    STEP_3: 'Đang tiến hành thanh toán',
    AUTOPAY: 'Thanh toán tự động',
    PENDING: 'Đang chờ',
    REJECT: 'Bị từ chối',
    SENDING: 'Đang gửi',
    WAITING_CALLBACK: 'Đang chờ phản hồi',
    PAYING: 'Đang thanh toán',
    WAITING_AUTO_PAY: 'Đang chờ thanh toán tự động',
  },
  withdrawConfirmStatus: {
    0: 'Chưa xác nhận',
    1: 'Đã nạp',
  },
  outboxStatus: {
    0: 'Chưa có phản hồi',
    1: 'Hoàn tất phản hồi',
  },
  editCheckedStatus: {
    0: 'Chưa kiểm tra',
    1: 'Hoàn tất thay đổi',
    2: 'Kiểm tra thất bại',
  },
  transfer: {
    status: {
      sending: 'Đang gửi',
      success: 'Thành công',
      fail: 'Thất bại',
    },
    type: {
      deposit: 'Nạp tiền',
      withdraw: 'Rút tiền',
    },
  },
  moneyChange: {
    type: {
      DEPOSIT: 'Nạp tiền',
      WITHDRAW: 'Rút tiền',
      PROMO: 'Khuyến mãi',
      TRANSFER: 'Chuyển khoản',
      BET: 'Cược',
      WITHDRAW_FAIL: 'Rút tiền thất bại',
      ADJUST: 'Điều chỉnh',
      PAYOUT: 'Trả tiền',
      ROLLBACK: 'Khôi phục',
      PATCH: 'Cập nhật',
      BET_N_PAYOUT: 'Cược và trả tiền',
      AFFILIATE_TRANSFER: 'Chuyển khoản đối tác',
      VIP_REBATE: 'Hoàn tiền VIP',
    },
    subType: {
      DEPOSIT: 'Từ ví vào trò chơi',
      WITHDRAW: 'Từ trò chơi ra ví',
      TRASNFER_IN: 'Nhận chuyển khoản',
      TRANSFER_OUT: 'Gửi chuyển khoản',
      AFFILIATE_SETTLEMENT: 'Quyết toán đối tác',
      AFFILIATE_DEPOSIT: 'Chuyển khoản từ số dư',
      AFFILIATE_COMMISSION: 'Chuyển khoản từ hoa hồng',
    },
  },
  status: {
    monthSummary: {
      UNCLEAR: 'Không rõ ràng',
      CLEARING: 'Đang làm rõ',
      CLEARED: 'Đã làm rõ',
    },
    settlement: {
      CHECKING: 'Đang kiểm tra',
      PAY: 'Trả tiền',
      CLEARED: 'Đã làm rõ',
    },
    member: {
      NORMAL: 'Bình thường',
      FROZEN: 'Đóng băng',
    },
    gameMatch: {
      ACTIVE: 'Đang diễn ra',
      ENDED: 'Đã kết thúc',
    },
    gameMatchRecord: {
      PENDING_MATCH: 'Đang chờ kết thúc trận đấu',
      PENDING_SETTLE: 'Đang chờ quyết toán',
      SETTLED: 'Đã quyết toán',
      CANCEL: 'Hủy bỏ',
    },
    gameQuiz: {
      ONGOING: 'Đang diễn ra',
      CANCEL: 'Hủy bỏ',
      ENDED: 'Đã kết thúc',
    },
    gameQuizAnswer: {
      PENDING: 'Đang chờ',
      CANCEL: 'Hủy bỏ',
      WIN: 'Thắng',
      LOSE: 'Thua',
    },
    gift: {
      OPEN: 'Mở',
      CLOSE: 'Đóng',
    },
    giftRecord: {
      PENDING: 'Đang chờ',
      PROCESSING: 'Đang xử lý',
      COMPLETE: 'Hoàn thành',
      EXPIRED: 'Hết hạn',
      FAILED: 'Thất bại',
    },
    piggyBank: {
      SAVING: 'Đang tiết kiệm',
      CLAIMED: 'Đã yêu cầu',
    },
    uefaMatch: {
      PENDING: 'Đang chờ',
      ONGOING: 'Đang diễn ra',
      CANCEL: 'Hủy bỏ',
      ENDED: 'Đã kết thúc',
    },
    uefaMatchRecord: {
      PENDING: 'Đang chờ',
      LOSE: 'Thua',
      WIN_MATCH: 'Thắng trận đấu',
      WIN_TEAM: 'Thắng đội',
      WIN_ALL: 'Thắng tất cả',
      CANCEL: 'Hủy bỏ',
    },
  },
  betStatus: {
    BET: 'Đặt cược',
    UNSETTLED: 'Chưa quyết toán',
    SETTLED: 'Đã quyết toán',
    CANCEL: 'Hủy bỏ',
    GIVEREWARD: 'Thưởng',
  },
  giftType: {
    ENTITY: 'Thực thể',
    VIRTUAL: 'Ảo',
  },
  dashboard: {
    memberCount: 'Số lượng thành viên',
    totalFirstDeposit: 'Tổng số tiền nạp đầu tiên',
    totalDeposit: 'Tổng số tiền nạp',
    totalTransaction: 'Số lượng giao dịch',
    totalTransfer: 'Tổng số tiền chuyển khoản',
    totalWithdraw: 'Tổng số tiền rút',
    totalWinLoss: 'Tổng thắng/thua',
    totalValidBet: 'Tổng cược hợp lệ',
    totalBonus: 'Tổng tiền thưởng',
    totalProfit: 'Tổng lợi nhuận',
    returnAmount: 'Số tiền hoàn trả',
    totalActiveMember: 'Tổng số thành viên hoạt động',
    newRegisterMember: 'Thành viên đăng ký mới',
    totalVisit: 'Tổng số lượt truy cập',
    depositChartName: 'Tổng số tiền nạp',
    depositAmount: 'Số tiền nạp',
    depositMemberCount: 'Số lượng thành viên nạp tiền',
    depositTransactionCount: 'Số lượng giao dịch nạp tiền',
    depositTransactionNumChartName:
      'Số lần nạp tiền của thành viên / Số lượng giao dịch nạp tiền',
    financialLevelRatio: 'Tỷ lệ mức tài chính',
    vipLevelRatio: 'Tỷ lệ mức VIP',
    paymentMethod: 'Phương thức thanh toán',
    ratio: 'Tỷ lệ',
    fundAmount: 'Quỹ',
    paymentMethodSummary: 'Tóm tắt phương thức thanh toán nạp tiền',
    total: 'Tổng cộng',
    bet: 'Đặt cược',
    payout: 'Trả tiền',
    merchantDepositSummary: 'Tóm tắt nạp tiền của thương nhân',
    onlineStatsCompareChartName: 'So sánh thống kê trực tuyến hàng ngày',
    onlineStatsChartName: 'Thống kê trực tuyến',
    rfdRegisterCount: 'Số lượng đăng ký thành viên',
    rfdFDepositCount: 'Số lượng thành viên nạp tiền đầu tiên',
    rfdReport: 'Báo cáo đăng ký và nạp tiền đầu tiên',
  },
  reportGame: {
    gamePlatform: 'Nền tảng trò chơi',
    gameMemberCount: 'Số lượng thành viên cược hàng ngày',
    gameBetCount: 'Số lượng giao dịch cược hàng ngày',
    gameBetAmount: 'Số tiền cược hàng ngày',
    gameWinLoss: 'Số tiền thắng/thua hàng ngày',
    gameMemberCountTotal: 'Tổng số thành viên cược',
    gameBetCountTotal: 'Tổng số giao dịch cược',
    gameBetAmountTotal: 'Tổng số tiền cược',
    gameValidBetAmountTotal: 'Tổng số tiền đặt cược hiệu quả',
    gamePayoutAmountTotal: 'Tổng số tiền thanh toán',
    gameWinLossTotal: 'Tổng số tiền thắng/thua',
    totalValidBet: "Tổng Cược Hợp Lệ"
  },
  gameType: {
    ALL: 'Toàn bộ',
    SLOT: 'Slot',
    LIVE: 'Trực tiếp',
    FISH: 'Cá',
    SPORT: 'Thể thao',
    ESPORT: 'Thể thao điện tử',
    POKER: 'Poker',
    LOTTERY: 'Xổ số',
    CASUAL: 'Casual',
    NBA: 'NBA',
    COCKFIGHT: 'Đá gà',
    MSI: 'MSI',
  },
  homeBannerType: {
    HOME: 'Trang chủ',
    LIVE: 'Trực tiếp',
    SLOT: 'Slot',
    FISH: 'Cá',
    POKER: 'Poker',
    PROMO: 'Khuyến mãi',
    HOMEPROMO: 'Khuyến mãi trang chủ',
    HOMEPOP: 'Popup trang chủ',
    CENTERPROMO: 'Khuyến mãi trung tâm',
    LOGIN: 'Đăng nhập',
    REGISTER: 'Đăng ký',
    VIP: 'VIP',
  },
  distributeStatus: {
    PENDING: 'Đang chờ',
    IN_PROGRESS: 'Đang phân phối',
    DISTRIBUTED: 'Đã phân phối',
    CANCEL: 'Hủy bỏ',
  },
  referFriendEvent: {
    INFO: 'Hoàn tất thông tin',
    FIRST: 'Nạp tiền lần đầu',
    SECOND: 'Nạp tiền lần hai',
  },
  rollover: {
    recordStatus: {
      ONGOING: 'Đang diễn ra',
      COMPLETED: 'Hoàn thành',
      VOID: 'Vô hiệu',
      CANCEL: 'Hủy bỏ',
    },
  },
  rebateLevel: {
    LEVEL_0: 'Cấp 0',
    LEVEL_1: 'Cấp 1',
    LEVEL_2: 'Cấp 2',
    LEVEL_3: 'Cấp 3',
    LEVEL_4: 'Cấp 4',
    LEVEL_5: 'Cấp 5',
    LEVEL_6: 'Cấp 6',
    LEVEL_7: 'Cấp 7',
    LEVEL_8: 'Cấp 8',
    LEVEL_9: 'Cấp 9',
    LEVEL_10: 'Cấp 10',
    LEVEL_11: 'Cấp 11',
    LEVEL_12: 'Cấp 12',
    LEVEL_13: 'Cấp 13',
  },
  gameQuiz: {
    questions: {
      1: 'Đội nào đã giành chiến thắng?',
      2: 'Handicap (bao gồm thêm giờ)',
      3: 'Tổng số giết trong vòng (bao gồm thêm giờ)',
      4: 'Tổng số vòng/giết của cả hai đội',
      5: 'Đội nào có số giết nhiều nhất?',
      6: 'Đội nào đã có lần giết đầu tiên?',
    },
    answers: {
      CSGO: {
        handicapAbove: 'Hai đội có chênh lệch dưới 4.5',
        handicapBelow: 'Hai đội có chênh lệch trên 4.5',
        killBig: 'Tổng số vòng trên 21.5',
        killSmall: 'Tổng số vòng dưới 21.5',
        killSignal: 'Đơn lẻ',
        killDouble: 'Kép',
      },
      LOL: {
        handicapAbove: 'Hai đội có chênh lệch dưới 4.5',
        handicapBelow: 'Hai đội có chênh lệch trên 4.5',
        killBig: 'Tổng số giết trên 25.5',
        killSmall: 'Tổng số giết dưới 25.5',
        killSignal: 'Đơn lẻ',
        killDouble: 'Kép',
      },
      DOTA2: {
        handicapAbove: 'Hai đội có chênh lệch dưới 10.5',
        handicapBelow: 'Hai đội có chênh lệch trên 10.5',
        killBig: 'Tổng số giết trên 49.5',
        killSmall: 'Tổng số giết dưới 49.5',
        killSignal: 'Đơn lẻ',
        killDouble: 'Kép',
      },
    },
  },
  promoLabel: {
    new: 'Mới',
    hot: 'Phổ biến',
    normal: 'Bình thường',
    recommend: 'Đề xuất',
    daily: 'Hàng ngày',
    newbie: 'Người mới',
    limit: 'Giới hạn',
    featured: 'Nổi bật',
  },
  messageType: {
    NOTIFICATION: 'Thông báo',
    ACTIVITY: 'Hoạt động',
    ANNOUNCEMENT: 'Thông cáo',
    PAYMENT: 'Thanh toán',
  },
  sitePlatform: {
    followType: 'Loại',
    follow: 'Trước khi theo dõi',
    new: 'Cập nhật loại trò chơi',
  },
  sortType: {
    ASC: 'Tăng dần',
    DESC: 'Giảm dần',
  },
  promoType: {
    WELCOME: 'Chào mừng',
    SPORT: 'Thể thao',
    ESPORT: 'Thể thao điện tử',
    FISH: 'Cá',
    LIVECASINO: 'Casino trực tiếp',
    SLOTGAME: 'Trò chơi slot',
    POKER: 'Poker',
    DAILY: 'Hàng ngày',
    FTD: 'Nạp tiền đầu tiên',
    LOTTERY: 'Xổ số',
    OTHER: 'Khác',
    LIMITED: 'LIMITED',
    SLOTWELCOME: 'Slot - Welcome',
    SLOTDAILY: 'Slot - Daily Weekly',
    SLOTOTHER: 'Slot - Rebate & Other',
  },
  scheduleType: {
    NORMAL: 'Bình thường',
    BET_RECORDS: 'Lịch sử cược',
  },
  summonFailReason: {
    PRIVI_IS_CLOSED: 'Đặc quyền đã kết thúc',
    PRIVI_HAS_ENDED: 'Đặc quyền đã hết hạn',
    PRIVI_HAVENT_START: 'Đặc quyền chưa bắt đầu',
    SUMMONER_VIP_LEVEL_NOT_REACH: 'Cấp độ VIP của người triệu hồi chưa đạt',
    MEMBER_VIP_LEVEL_EXCEED_1: 'Cấp độ VIP của thành viên đã vượt quá',
    MEMBER_HISTORY_DEPOSIT_AMOUNT_NOT_REACH:
      'Số tiền nạp lịch sử của thành viên chưa đạt',
    MEMBER_DEPOSIT_IN_PAST_15_DAYS: 'Thành viên đã nạp tiền trong 15 ngày qua',
    MEMBER_BET_IN_PAST_15_DAYS: 'Thành viên đã cược trong 15 ngày qua',
    MEMBER_AND_SUMMONER_SAME_REGISTER_DETAIL:
      'Thông tin đăng ký của thành viên và người triệu hồi là giống nhau',
    MEMBER_ALREADY_SUMMONED: 'Thành viên đã được triệu hồi',
  },
  smsSend: {
    template1: 'Welcome New Member',
    template2: 'Deposit Promotion',
    template3: 'Welcome Back',
  },
  displayLocation: {
    HOMEPAGE: 'TRANG CHỦ',
    PROMO_PAGE: 'TRANG KHUYẾN MÃI',
    DEPOSIT_PAGE: 'TRANG GỬI TIỀN',
    WITHDRAW_PAGE: 'TRANG RÚT',
    PERSONAL_CENTRE: 'TRUNG TÂM CÁ NHÂN',
  },
  mode: {
    DYNAMIC: 'Trang nổi',
    STATIC: 'Hiển thị trực tiếp',
  },
  autoplayStatus: {
    ON: 'bật',
    OFF: 'tắt',
  },
  valueRulesType: {
    INPUT: 'Nhập',
    RADIO: 'Radio',
    SELECT: 'Chọn',
    SWITCH: 'Công tắc',
    CHECKBOX: 'Hộp kiểm',
  },
  siteType: {
    main: 'MAIN',
    slot: 'SLOT',
  },
  fields: {
    account: 'Tài khoản',
    accountHolder: 'Chủ sở hữu tài khoản',
    accountInfo: 'Thông tin tài khoản',
    accountNumber: 'Số tài khoản',
    accountStatus: 'Trạng thái tài khoản',
    accurate: 'Tìm kiếm chính xác',
    action: 'Hành động',
    actionType: 'Loại hành động',
    activeMember: 'Thành viên hoạt động',
    activeMemberCount: 'Số lượng thành viên hoạt động',
    activePlayer: 'Người chơi hoạt động',
    activeUser: 'Người dùng hoạt động',
    actualRate: 'Hình thuộc',
    add: 'Thêm',
    addCriditCard: 'Thêm thẻ ngân hàng',
    addAds: 'Thêm quảng cáo',
    addAdjustmentReason: 'Thêm lý do điều chỉnh',
    addAffiliate: 'Thêm đối tác liên kết',
    addAffiliateCommission: 'Thêm hoa hồng đối tác liên kết',
    addAffiliateDomain: 'Thêm miền liên kết',
    addAffiliateAnnouncement: 'Thêm thông báo đối tác liên kết',
    addAffiliateAnnouncementType: 'Thêm loại thông báo đối tác liên kết',
    addAffiliateFinancialConfig: 'Thêm cấu hình tài chính đối tác liên kết',
    addAnnouncement: 'Thêm thông báo',
    addAnnouncementType: 'Thêm loại thông báo',
    addBank: 'Thêm ngân hàng',
    addBankCard: 'Thêm thẻ ngân hàng',
    addBankToPayment: 'Thêm ngân hàng vào phương thức thanh toán',
    addBankToWithdrawPlatform: 'Thêm ngân hàng vào nền tảng rút tiền',
    addBanner: 'Thêm banner',
    addBbDacha: 'Thêm BB Dacha',
    addBlacklist: 'Thêm danh sách đen',
    addRewardGroup: 'Thêm nhóm thưởng',
    addCurrency: 'Thêm đơn vị tiền tệ',
    addCurrencyRate: 'Thêm tỷ giá tiền tệ',
    addDepositSetting: 'Thêm cài đặt gửi tiền',
    addDomain: 'Thêm domain',
    addEmail: 'Thêm email',
    addFinancialLevel: 'Thêm cấp độ tài chính',
    addGame: 'Thêm trò chơi',
    addGameMatch: 'Thêm trận đấu trò chơi',
    addGameQuiz: 'Thêm trò chơi trắc nghiệm',
    addGift: 'Thêm quà tặng',
    addIpLabel: 'Thêm nhãn IP',
    additionalTicketDetail: 'Thông tin vé bổ sung',
    additionalKey: 'Khóa bổ sung',
    addJob: 'Thêm công việc',
    addLimit: 'Thêm giới hạn',
    addLotteryResult: 'Thêm kết quả xổ số',
    addMember: 'Thêm thành viên',
    addMemberAmountAdjust: 'Thêm điều chỉnh số tiền thành viên',
    addMemberPrivilegeBlacklist: 'Thêm danh sách đen đặc quyền thành viên',
    addMemberRebateRule: 'Thêm quy tắc hoàn tiền thành viên',
    addMenu: 'Thêm menu',
    addNodes: 'Thêm nút',
    addPayType: 'Thêm loại thanh toán',
    addPlatform: 'Thêm nền tảng',
    addPlatformAccount: 'Thêm tài khoản nền tảng',
    addPrivilegeInfo: 'Thêm thông tin đặc quyền',
    addQuestionnaire: 'Thêm bảng câu hỏi',
    addRecipient: 'Thêm người nhận',
    addRemark: 'Thêm ghi chú',
    addRiskLevel: 'Thêm cấp độ rủi ro',
    addRole: 'Thêm vai trò',
    addSettings: 'Thêm cài đặt',
    addSite: 'Thêm site',
    addSitePlatform: 'Thêm nền tảng site',
    addStage: 'Thêm giai đoạn',
    addSystemMessage: 'Thêm tin nhắn hệ thống',
    addSyncDefault: 'Đồng bộ hóa cuộc thi trong vòng 3 ngày',
    addTelephone: 'Thêm số điện thoại',
    addTicket: 'Thêm vé',
    addUser: 'Thêm người dùng',
    addVip: 'Thêm VIP',
    addVipRebateRule: 'Thêm quy tắc hoàn tiền VIP',
    addWithdrawPlatform: 'Thêm nền tảng rút tiền',
    addWithdrawSetting: 'Thêm cài đặt rút tiền',
    addWithdrawalBankBlacklist: 'Thêm danh sách đen ngân hàng rút tiền',
    address: 'Địa chỉ',
    adjustAmount: 'Điều chỉnh số tiền',
    adjustBetAmount: 'Điều chỉnh số tiền cược',
    adjustBy: 'Điều chỉnh bởi',
    adjustReason: 'Lý do điều chỉnh',
    adjustTime: 'Thời gian điều chỉnh',
    adjustType: 'Loại điều chỉnh',
    adjustmentReasonType: 'Loại lý do điều chỉnh',
    adjustmentReason: 'Lý do điều chỉnh',
    adjustment: 'Điều chỉnh',
    adjustmentType: 'Loại điều chỉnh',
    affiliate: 'Đối tác liên kết',
    affiliateBankCard: 'Thẻ ngân hàng đối tác liên kết',
    affiliateBankCardRecord: 'Hồ sơ thẻ ngân hàng đối tác liên kết',
    affiliateCode: 'Mã đối tác liên kết',
    channelName: 'Tên kênh',
    affiliateDomainList: 'Danh sách miền liên kết',
    affiliateFinancialManagement: 'Quản lý tài chính đối tác liên kết',
    affiliateFBRequestList: 'Affiliate Facebook Pixel List',
    affiliateFBRequestUrl: 'Affiliate URL',
    affiliateDepositChannel: 'Kênh gửi tiền đối tác liên kết',
    affiliateH5Link: 'Liên kết H5 đối tác liên kết',
    affiliateInfo: 'Thông tin đối tác liên kết',
    affiliateLevel: 'Cấp độ đối tác liên kết',
    affiliateLink: 'Liên kết đối tác liên kết',
    affiliateList: 'Danh sách đối tác liên kết',
    affiliateName: 'Tên đối tác liên kết',
    affiliateShortUrlPlatform: 'Nền tảng URL ngắn đối tác liên kết',
    affiliateStatus: 'Trạng thái đối tác liên kết',
    affiliateTransferAmount: 'Số tiền chuyển khoản đối tác liên kết',
    affiliateTransferRecord: 'Hồ sơ chuyển khoản đối tác liên kết',
    affiliateWallet: 'Ví chuyển khoản đối tác liên kết',
    affiliateWebLink: 'Liên kết web đối tác liên kết',
    advancedSearch: 'Tìm kiếm nâng cao',
    afterAmount: 'Sau số tiền',
    afterBalance: 'Sau số dư',
    afterEdit: 'Sau khi chỉnh sửa',
    amount: 'Số tiền',
    amountGreaterThan: 'số tiền lớn hơn',
    amountLimitPerRain: 'Giới hạn số tiền mỗi lần mưa',
    adjust: 'Điều chỉnh',
    alias: 'Biệt danh',
    all: 'Tất cả',
    allBonus: 'Tất cả bonus',
    allvip: 'Tất cả VIP',
    allfinanciallevel: 'Tất cả cấp độ tài chính',
    allprofit: 'Tất cả lợi nhuận',
    allreviewstatus: 'Tất cả trạng thái đánh giá',
    allrisklevel: 'Tất cả cấp độ rủi ro',
    allmembers: 'Tất cả thành viên',
    allmembertype: 'Tất cả loại thành viên',
    announcement: 'Thông báo',
    announcementType: 'Loại thông báo',
    answer: 'Trả lời',
    answerOne: 'Trả lời câu hỏi 1',
    answerTwo: 'Trả lời câu hỏi 2',
    answerThree: 'Trả lời câu hỏi 3',
    appVersion: 'Phiên bản ứng dụng',
    applying: 'Đang xin',
    approve: 'Phê duyệt',
    approveBy: 'Phê duyệt bởi',
    assigned: 'Đã giao',
    assignment: 'Phân công',
    attachment: 'Tập tin đính kèm',
    auditTime: 'Thời gian kiểm toán',
    automaticPayment: 'Thanh toán tự động',
    autopay: 'Thanh toán tự động',
    autoplay: 'Tự động phát',
    automaticPay: 'Thanh toán tự động',
    autoWithdrawSetting: 'Cài đặt rút tiền tự động',
    awards: 'Giải thưởng',
    awayTeam: 'Đội khách',
    awayTeamIcon: 'Biểu tượng đội khách',
    back: 'Quay lại',
    balance: 'Số dư',
    balanceUrl: 'URL số',
    bank: 'Ngân hàng',
    bankAccount: 'Tài khoản ngân hàng',
    bankCard: 'Thẻ ngân hàng',
    bankCardNumber: 'Số thẻ ngân hàng',
    bankCode: 'Mã ngân hàng',
    bankName: 'Tên ngân hàng',
    bankNumber: 'Số ngân hàng',
    bankType: 'Loại ngân hàng',
    batchCancel: 'Hủy hàng loạt',
    bbDachaDetails: 'Chi tiết BB Dacha',
    bbdachaAnswers: 'Câu trả lời BB Dacha',
    bbdachaSetting: 'Cài đặt BB Dacha',
    beanName: 'Tên bean',
    beforeAmount: 'Số tiền trước điều chỉnh',
    beforeBalance: 'Số dư trước điều chỉnh',
    beforeEdit: 'Trước khi chỉnh sửa',
    bet: 'Cược',
    betAmount: 'Số tiền cược',
    betId: 'ID cược',
    belongType: 'Loại thuộc về',
    betMoneyChange: 'Thay đổi số tiền cược thành viên',
    betMoreThan: 'Cược nhiều hơn',
    betRebateDetails: 'Chi tiết hoàn tiền cược',
    betRebateRecord: 'Hồ sơ hoàn tiền cược',
    betRecords: 'Hồ sơ cược',
    betStatus: 'Trạng thái cược',
    betTime: 'Thời gian cược',
    betTotal: 'Tổng tiền cược',
    betWheelSettings: 'Cài đặt bánh xe cược',
    betWheelRecords: 'Hồ sơ bánh xe cược',
    binded: 'Đã kết nối',
    birthday: 'Ngày sinh',
    bindTypeAll: 'Tất cả',
    bindTypeNew: 'Mới',
    bindTypeRemove: 'Gỡ bỏ',
    blacklisted: 'Đã vào danh sách đen',
    bonus: 'Tiền thưởng',
    bonusAmount: 'Số tiền thưởng',
    bonusDays: 'Số ngày thưởng',
    bonusReceived: 'Tiền thưởng nhận được',
    bonusType: 'Loại tiền thưởng',
    byDepositDateDesc: 'Sắp xếp theo ngày gửi tiền giảm dần',
    byDepositDateAsc: 'Sắp xếp theo ngày gửi tiền tăng dần',
    byFinishDateDesc: 'Sắp xếp theo ngày hoàn thành giảm dần',
    byFinishDateAsc: 'Sắp xếp theo ngày hoàn thành tăng dần',
    byPaymentDateDesc: 'Sắp xếp theo ngày thanh toán giảm dần',
    byPaymentDateAsc: 'Sắp xếp theo ngày thanh toán tăng dần',
    byWithdrawDateDesc: 'Sắp xếp theo ngày rút tiền giảm dần',
    byWithdrawDateAsc: 'Sắp xếp theo ngày rút tiền tăng dần',
    byprofitasc: 'Sắp xếp theo lợi nhuận tăng dần',
    byprofitdesc: 'Sắp xếp theo lợi nhuận giảm dần',
    bydateasc: 'Sắp xếp theo ngày tăng dần',
    bydatedesc: 'Sắp xếp theo ngày giảm dần',
    bulkApprove: 'Phê duyệt hàng loạt',
    callbackUrl: 'URL gọi lại',
    cancel: 'Hủy',
    cancelBbDacha: 'Hủy BB Dacha',
    cancelBySearch: 'Hủy tìm kiếm',
    cancelDeposit: 'Hủy nạp tiền',
    cancelGameMatch: 'Hủy trận đấu game',
    cancelGift: 'Hủy đổi quà',
    cancelMatch: 'Hủy trận đấu',
    cancelQuiz: 'Hủy câu đố',
    cancelRemark: 'Hủy ghi chú',
    cancelRolloverRecord: 'Hủy ghi chép rollover',
    cancelAllRolloverRecord: 'Hủy tất cả ghi chép rollover',
    cancelUpdate: 'Hủy cập nhật',
    cancelType: 'Loại hủy',
    cardAccount: 'Tài khoản thẻ',
    cardAddress: 'Địa chỉ thẻ',
    cardNumber: 'Số thẻ',
    cardTime: 'Thời gian thao tác',
    cardTypeAll: 'Tất cả',
    cardTypeBank: 'Ngân hàng',
    cardTypeWallet: 'Ví điện tử',
    cardTypeCrypto: 'Tiền điện tử',
    category: 'Danh mục',
    cause: 'Nguyên nhân',
    changeAffiliate: 'Thay đổi đối tác',
    check: 'Kiểm tra',
    checkall: 'Chọn tất cả',
    checkBy: 'Người kiểm tra',
    checkDate: 'Ngày kiểm tra',
    checkExclusiveUrl: 'Kiểm tra URL độc quyền',
    checkFail: 'Kiểm tra thất bại',
    checkTime: 'Thời gian kiểm tra',
    choice: 'Lựa chọn',
    choiceOne: 'Lựa chọn câu hỏi 1',
    choiceTwo: 'Lựa chọn câu hỏi 2',
    choiceThree: 'Lựa chọn câu hỏi 3',
    claimTime: 'Thời gian yêu cầu',
    className: 'Tên lớp',
    clearingSum: 'Số tiền thanh toán',
    clientType: 'Loại khách hàng',
    close: 'Đóng',
    code: 'Mã',
    color: 'Màu sắc',
    commission: 'Hoa hồng',
    commissionBalance: 'Số dư hoa hồng',
    commissionModel: 'Mô hình hoa hồng',
    commissionPercent: 'Tỷ lệ hoa hồng (%)',
    commissionRate: 'Tỷ lệ hoa hồng',
    commissionReport: 'Báo cáo hoa hồng',
    commissionWallet: 'Ví hoa hồng',
    commitTime: 'Thời gian cam kết',
    companyProfit: 'Lợi nhuận công ty',
    configGroup: 'Nhóm cấu hình',
    componentName: 'Tên thành phần',
    componentPath: 'Đường dẫn thành phần',
    composeMessage: 'Soạn tin nhắn',
    consumingTime: 'Thời gian tiêu hao',
    confirm: 'Xác nhận',
    confirmAndImport: 'Xác nhận và nhập',
    confirmAndSend: 'Confirm and Send',
    confirmNewPassword: 'Xác nhận mật khẩu mới',
    confirmPassword: 'Xác nhận mật khẩu',
    confirmPay: 'Xác nhận thanh toán',
    confirmStatus: 'Xác nhận trạng thái',
    confirmBy: 'Người xác nhận',
    content: 'Nội dung',
    contentList: 'Danh sách nội dung',
    convertRate: 'Tỷ lệ chuyển đổi',
    copy: 'Sao chép',
    copyPayment: 'Sao chép thanh toán',
    copyWithdrawPlatform: 'Sao chép nền tảng rút tiền',
    copyTo: 'Sao chép tới',
    copyToOtherLevel: 'Sao chép sang cấp khác',
    country: 'Quốc gia',
    countryImage: 'Hình ảnh quốc gia',
    createBy: 'Tạo bởi',
    createTime: 'Thời gian tạo',
    createVote: 'Tạo phiếu bầu',
    cronExpression: 'Biểu thức cron',
    currency: 'Tiền tệ',
    currencyCode: 'Mã tiền tệ',
    currencyName: 'Tên tiền tệ',
    currencyRate: 'Tỷ giá tiền tệ',
    currencyWallet: 'ví tiền tệ',
    currentPassword: 'Mật khẩu hiện tại',
    currentPlace: 'Vị trí hiện tại',
    customerSupportAddress: 'Địa chỉ hỗ trợ khách hàng',
    dailyDeposit: 'Nạp tiền hàng ngày',
    dailyDepositAmount: 'Số tiền nạp hàng ngày',
    dailyMin: 'Số quay tối thiểu hàng ngày',
    dailyMinRegLimit: 'Giới hạn đăng ký tối thiểu hàng ngày',
    dailyWithdraw: 'Rút tiền hàng ngày',
    dailyWithdrawAmount: 'Số tiền rút hàng ngày',
    dailyFailAmount: 'Số tiền thất bại hàng ngày',
    dailyFailDeposit: 'Nạp tiền thất bại hàng ngày',
    dailyFirstDepositLimit: 'Giới hạn tiền gửi lần đầu hàng ngày',
    dailyMinFirstDepositLimit: 'Giới hạn tiền gửi lần đầu tối thiểu hàng ngày',
    dailyMaxFirstDepositLimit: 'Giới hạn tiền gửi lần đầu tối đa hàng ngày',
    dailyFirstDepositProbability: 'Xác suất gửi tiền lần đầu hàng ngày',
    dailyMemberFailDeposit: 'Thành viên nạp tiền thất bại hàng ngày',
    dailyFailWithdraw: 'Rút tiền thất bại hàng ngày',
    dailyMax: 'Số quay tối đa hàng ngày',
    dailyMaxRegLimit: 'Giới hạn đăng ký tối đa hàng ngày',
    dailyRegProbabilty: 'Xác suất đăng ký hàng ngày',
    dailyMemberFailWithdraw: 'Thành viên rút tiền thất bại hàng ngày',
    dailyRainDuration: 'Thời gian mưa',
    dailyRefreshDuration: 'Thời gian làm mới',
    dailyRegLimit: 'Giới hạn đăng ký hàng ngày',
    dailySuccessAmount: 'Số tiền thành công hàng ngày',
    dailySuccessDeposit: 'Nạp tiền thành công hàng ngày',
    dailyMemberSuccessDeposit: 'Thành viên nạp tiền thành công hàng ngày',
    dailySuccessWithdraw: 'Rút tiền thành công hàng ngày',
    dailyMemberSuccessWithdraw: 'Thành viên rút tiền thành công hàng ngày',
    dailySuccessPercentage: 'Tỷ lệ thành công hàng ngày',
    dailyTotalAmount: 'Tổng số tiền hàng ngày',
    dailyTotalRedeem: 'Tổng đổi thưởng hàng ngày',
    dayWithdrawCount: 'Số lần rút tiền hàng ngày',
    day1: 'Mở khóa ngày 1',
    day2: 'Mở khóa ngày 2',
    day3: 'Mở khóa ngày 3',
    day4: 'Mở khóa ngày 4',
    day5: 'Mở khóa ngày 5',
    day6: 'Mở khóa ngày 6',
    date: 'Ngày',
    data: 'Dữ liệu',
    dataBefore: 'Dữ liệu trước',
    dataAfter: 'Dữ liệu sau',
    dateReceived: 'Ngày nhận',
    dateType: 'Loại ngày',
    deduct: 'Khấu trừ',
    deductMemberAmountAdjust: 'Khấu trừ điều chỉnh số tiền thành viên',
    defaultBonus: 'Thưởng mặc định',
    defaultFinancialLevel: 'Mức tài chính mặc định',
    defaultAgentFinancialLevel: 'Mức tài chính đại lý mặc định',
    defaultRiskLevel: 'Mức rủi ro mặc định',
    defaultAgentRiskLevel: 'Mức rủi ro đại lý mặc định',
    defaultVip: 'VIP mặc định',
    defaultAgentVip: 'VIP đại lý mặc định',
    delete: 'Xóa',
    runAtOnce: 'Chạy ngay lập tức',
    deleteRole: 'Xóa vai trò',
    deliver: 'Giao hàng',
    delivered: 'Đã giao hàng',
    deliverGift: 'Giao quà',
    deposit: 'Nạp tiền',
    depositAmount: 'Số tiền nạp',
    depositAverage: 'Số tiền nạp trung bình',
    depositBettingAmount: 'Số tiền nạp/cược',
    depositCount: 'Số lần nạp tiền',
    depositDate: 'Ngày nạp tiền',
    deposited: 'Đã nạp tiền',
    depositExchangeRate: 'Tỷ giá hối đoái nạp tiền',
    depositMemberCount: 'Số lượng thành viên nạp tiền',
    depositPerSpin: 'Nạp tiền mỗi vòng quay',
    depositSerialNo: 'Số seri nạp tiền',
    depositStatus: 'Trạng thái nạp tiền',
    depositWithdrawalProfit: 'Lợi nhuận nạp/rút tiền',
    describe: 'Mô tả',
    description: 'Mô tả',
    desktopImage: 'Hình nền máy tính để bàn',
    desktopBackgroundImage: 'Hình nền máy tính để bàn',
    desktopBanner: 'Biểu ngữ máy tính để bàn',
    details: 'Chi tiết',
    device: 'Thiết bị',
    dif: 'Chênh lệch nạp tiền',
    disable: 'Vô hiệu hóa',
    disableAffiliate: 'Vô hiệu hóa đối tác',
    disableAll: 'Vô hiệu hóa tất cả',
    disableType: 'Loại vô hiệu hóa',
    displayUrl: 'Url hiển thị',
    distributed: 'Đã phân phối',
    distributeBy: 'Người phân phối',
    distributePrivilege: 'Phân phối đặc quyền',
    distributeRebate: 'Phân phối hoàn tiền',
    distributeRefer: 'Phân phối thưởng giới thiệu',
    distributeTime: 'Thời gian phân phối',
    domain: 'Miền',
    domainCount: 'Số lượng miền',
    domainList: 'Danh sách miền',
    done: 'Hoàn thành',
    doneBy: 'Người hoàn thành',
    downgradeToApplying: 'Hạ cấp xuống đang áp dụng',
    downgradeToWaitPay: 'Hạ cấp xuống chờ thanh toán',
    downlineAffiliate: 'Đối tác tuyến dưới',
    downlineAffiliatePaymentChannel: 'Kênh thanh toán đối tác tuyến dưới',
    downlineCommission: 'Hoa hồng tuyến dưới',
    downlineCommissionRate: 'Tỷ lệ hoa hồng tuyến dưới',
    downlineMember: 'Thành viên tuyến dưới',
    downlineProfit: 'Lợi nhuận tuyến dưới',
    downloadTemplate: 'Tải xuống mẫu',
    draw: 'Rút thăm',
    edit: 'Chỉnh sửa',
    editAds: 'Chỉnh sửa quảng cáo pop-up',
    editAdjustmentReason: 'Chỉnh sửa lý do điều chỉnh',
    editAffiliateCommission: 'Chỉnh sửa hoa hồng đại lý',
    editAffiliateDomain: 'Chỉnh sửa miền đại lý',
    editAffiliateAnnouncement: 'Chỉnh sửa thông báo đại lý',
    editAffiliateAnnouncementType: 'Chỉnh sửa loại thông báo đại lý',
    editAffiliateFinancialConfig: 'Chỉnh sửa cấu hình tài chính đại lý',
    editAmount: 'Chỉnh sửa số tiền',
    editAnnouncement: 'Chỉnh sửa thông báo',
    editAnnouncementType: 'Chỉnh sửa loại thông báo',
    editBank: 'Chỉnh sửa ngân hàng',
    editBankCard: 'Chỉnh sửa thẻ ngân hàng',
    editBanner: 'Chỉnh sửa biểu ngữ',
    editBelongType: 'Chỉnh sửa loại thuộc về',
    editBlacklist: 'Chỉnh sửa danh sách đen',
    editConfig: 'Chỉnh sửa cấu hình',
    editCurrency: 'Chỉnh sửa tiền tệ',
    editCurrencyRate: 'Chỉnh sửa tỷ giá hối đoái',
    editDepositSetting: 'Chỉnh sửa cài đặt nạp tiền',
    editDetails: 'Chỉnh sửa chi tiết',
    editEmail: 'Chỉnh sửa email',
    editFinancialLevel: 'Chỉnh sửa mức tài chính',
    editGame: 'Chỉnh sửa trò chơi',
    editGift: 'Chỉnh sửa quà tặng',
    editIpLabel: 'Chỉnh sửa nhãn IP',
    editJob: 'Chỉnh sửa công việc',
    editMemberRebateRule: 'Chỉnh sửa quy tắc hoàn tiền thành viên',
    editMenu: 'Chỉnh sửa menu',
    editPayType: 'Chỉnh sửa loại thanh toán',
    editPlatform: 'Chỉnh sửa nền tảng',
    editPlatformAccount: 'Chỉnh sửa tài khoản nền tảng',
    editPrivilegeInfo: 'Chỉnh sửa thông tin đặc quyền',
    editQuestionnaire: 'Chỉnh sửa bảng câu hỏi',
    editRemark: 'Chỉnh sửa ghi chú',
    editRewardGroup: 'Chỉnh sửa nhóm thưởng',
    editRiskLevel: 'Chỉnh sửa mức rủi ro',
    editRole: 'Chỉnh sửa vai trò',
    editSettings: 'Chỉnh sửa cài đặt',
    editSite: 'Chỉnh sửa trang web',
    editSitePlatform: 'Chỉnh sửa nền tảng trang web',
    editStage: 'Chỉnh sửa giai đoạn',
    editTelephone: 'Chỉnh sửa số điện thoại',
    editTimeType: 'Chỉnh sửa loại thời gian',
    editType: 'Chỉnh sửa loại',
    editUser: 'Chỉnh sửa người dùng',
    editVip: 'Chỉnh sửa VIP',
    editVipRebateRule: 'Chỉnh sửa quy tắc hoàn tiền VIP',
    editWithdrawPlatform: 'Chỉnh sửa nền tảng rút tiền',
    editWithdrawSetting: 'Chỉnh sửa cài đặt rút tiền',
    email: 'Email',
    endBbDacha: 'Kết thúc BB Dacha',
    endDate: 'Ngày kết thúc',
    endMatch: 'Kết thúc trận đấu',
    endQuiz: 'Kết thúc câu đố',
    endTime: 'Thời gian kết thúc',
    estimateRate: 'Tỷ giá hơn nhất',
    event: 'Sự kiện',
    exchangeRate: 'Tỷ giá hối đoái',
    exclusiveUrlWeb: 'URL độc quyền (web)',
    exclusiveUrlWap: 'URL độc quyền (ứng dụng)',
    exclusiveUrlApp: 'URL độc quyền',
    exclusiveUrlCashier: 'URL độc quyền',
    exclusiveUrl: 'URL độc quyền',
    exportToExcel: 'Xuất ra Excel',
    expressCompany: 'Công ty chuyển phát nhanh',
    externalUrl: 'url bên ngoài',
    extraParam: 'Tham số bổ sung',
    fail: 'Thất bại',
    failReason: 'Lý do thất bại',
    fbid: 'Facebook Pixel ID',
    fbToken: 'Facebook Pixel Token',
    fdConvertRate: 'Tỷ giá chuyển đổi FTD',
    features: 'Tính năng',
    fee: 'Phí',
    feedbackType: 'Loại phản hồi',
    feedbackTypeAll: 'Tất cả',
    feedbackTypeDeposit: 'Nạp tiền',
    feedbackTypeWithdraw: 'Rút tiền',
    feedbackStatus: 'Trạng thái phản hồi',
    feedbackStatusAll: 'Tất cả',
    feedbackStatusPending: 'Đang chờ xử lý',
    feedbackStatusSuccess: 'Thành công',
    feedbackTime: 'Thời gian phản hồi',
    feeRate: 'Tỷ lệ phí',
    subtractAmount: 'Số tiền khấu trừ',
    field: 'Trường',
    finalSum: 'Tổng số cuối cùng',
    financialLevel: 'Mức tài chính',
    financeType: 'Loại',
    financeRemark: 'Ghi chú tài chính',
    financeStatus: 'Trạng thái',
    financePhotos: 'Ảnh',
    finishDate: 'Ngày hoàn thành',
    firstDeposit: 'Lần nạp tiền đầu tiên',
    firstTicket: 'Vé đầu tiên (cược/nạp tiền)',
    firstDepositMemberCount: 'Số lượng thành viên nạp tiền lần đầu',
    firstDepositMember: 'Thành viên nạp tiền lần đầu',
    freeze: 'Đóng băng',
    freezeMember: 'Đóng băng thành viên',
    unfreezeMember: 'Giải đóng băng thành viên',
    freezeType: 'Loại đóng băng',
    frequency: 'Tần suất',
    fromWallet: 'Điều chỉnh từ ví chính',
    fromCommission: 'Điều chỉnh từ hoa hồng',
    ftd: 'FTD',
    ftdAverage: 'FTD trung bình',
    ftdAmount: 'Số tiền FTD',
    ftdCount: 'Số lần FTD',
    ftdTime: 'Thời gian FTD',
    ftdTxn: 'Giao dịch FTD',
    fundingInfo: 'Thông tin tài trợ',
    gameAccountName: 'Tên tài khoản trò chơi',
    gameCode: 'Mã trò chơi',
    gameConsolidateReport: 'Báo cáo hợp nhất trò chơi',
    gameMatch: 'Trận đấu trò chơi',
    gameMatchRecord: 'Hồ sơ trận đấu trò chơi',
    gameName: 'Tên trò chơi',
    gameQuiz: 'Câu đố trò chơi',
    gameQuizAnswer: 'Câu trả lời câu đố trò chơi',
    gameStepsRecords: 'Hồ sơ bước trò chơi',
    gameStepsSettings: 'Cài đặt bước trò chơi',
    gameType: 'Loại trò chơi',
    generateSummary: 'Tạo tóm tắt',
    groupId: 'ID nhóm',
    gift: 'Quà tặng',
    giftCode: 'Mã quà tặng',
    giftName: 'Tên quà tặng',
    giftRecord: 'Hồ sơ quà tặng',
    hasPromo: 'Có chương trình khuyến mãi',
    hidden: 'Ẩn',
    homeTeam: 'Đội nhà',
    homeTeamIcon: 'Biểu tượng đội nhà',
    icon: 'Biểu tượng',
    pIcon: 'Biểu tượng đặc quyền',
    id: 'ID',
    identifyCode: 'Mã nhận dạng',
    imageSize: 'Kích thước hình ảnh',
    import: 'Nhập khẩu',
    inbox: 'Hộp thư đến',
    ineligible: 'Không đủ điều kiện',
    innerSetting: 'Cài đặt bên trong',
    ip: 'IP đăng nhập',
    isDeposit: 'Hiển thị nạp tiền',
    isMultiple: 'Có phải lựa chọn nhiều không',
    itsYou: 'Là bạn',
    job: 'Công việc',
    jobLog: 'Nhật ký công việc',
    jobName: 'Tên công việc',
    jobType: 'Loại công việc',
    label: 'Nhãn',
    labelCancel: 'Hủy bỏ',
    lastDepositTime: 'Last Deposit Time',
    lastDigit: 'Chữ số cuối',
    lastDigitMinDayDeposit: 'Số tiền gửi tối thiểu hàng ngày cho gói cuối cùng',
    lastDigitRules: 'Quy tắc gói cuối cùng',
    lastLogin: 'Đăng nhập cuối',
    lastLoginAddress: 'Địa chỉ đăng nhập cuối',
    lastLoginIp: 'IP đăng nhập cuối',
    lastLoginTime: 'Thời gian đăng nhập cuối',
    lastMonth: 'Tháng trước',
    last3Months: '3 tháng trước',
    last6Months: '6 tháng trước',
    last5Min: '5 phút trước',
    last15Min: '15 phút trước',
    last30Min: '30 phút trước',
    last1Hour: '1 giờ trước',
    lastWeek: 'Tuần trước',
    latestFetchBetRecordTime: 'Thời gian lấy lịch sử cược mới nhất',
    level: 'Cấp độ',
    levelUpCredit: 'Tín dụng tăng cấp',
    limitNumber: 'Giới hạn số lượng',
    localCurrencyAmount: 'Số tiền địa phương',
    lockStatus: 'Trạng thái khóa',
    login: 'Đăng nhập',
    loginAddress: 'Địa chỉ đăng nhập',
    loginFailedReason: 'Lý do đăng nhập thất bại',
    loginInfo: 'Thông tin đăng nhập',
    loginIp: 'IP đăng nhập',
    loginName: 'Tên đăng nhập',
    loginNameSeparateComma: 'Tên đăng nhập (phân tách bằng dấu phẩy)',
    loginStatus: 'Trạng thái đăng nhập',
    loginTime: 'Thời gian đăng nhập',
    loginUrl: 'URL đăng nhập',
    loginWay: 'Cách đăng nhập',
    logoutPlayer: 'Đăng xuất người chơi',
    lotteryRecordList: 'Danh sách lịch sử xổ số',
    lotteryResultList: 'Danh sách kết quả xổ số',
    lowBalance: 'Số dư thấp',
    maintenanceEndTime: 'Thời gian kết thúc bảo trì',
    maintenanceStartTime: 'Thời gian bắt đầu bảo trì',
    maintenanceTime: 'Thời gian bảo trì',
    mallCode: 'Mã cửa hàng',
    mallExtraKey: 'Khóa bổ sung cửa hàng',
    mallKey: 'Khóa cửa hàng',
    mallName: 'Tên cửa hàng',
    manualPay: 'Thanh toán thủ công',
    massImport: 'Nhập khẩu hàng loạt',
    messageAffiliateDepositDisplay:
      'Thiết lập hiển thị khoản tiền gửi mới cho đối tác.',
    matchTime: 'Thời gian trận đấu',
    matchTitle: 'Tiêu đề trận đấu',
    matureTime: 'Thời gian bắt động',
    maxBonus: 'Tiền thưởng tối đa',
    maxDailyWithdraw: 'Số tiền rút tối đa hàng ngày',
    maxDailyWithdrawTimes: 'Số lần rút tiền tối đa hàng ngày',
    maxDeposit: 'Số tiền gửi tối đa',
    maxMemberClaimCountPerRain: 'Số lần yêu cầu tối đa mỗi người trong mưa',
    maxMemberClaimCountPerDay: 'Số lần yêu cầu tối đa mỗi ngày',
    maxTicket: 'Vé tối đa',
    maxWithdrawAmount: 'Số tiền rút tối đa',
    mediaDesc: 'Tên phương tiện',
    mediaUrl: 'địa chỉ phương tiện',
    member: 'Thành viên',
    memberBankCard: 'Thẻ ngân hàng thành viên',
    memberBankCardRecord: 'Lịch sử thẻ ngân hàng thành viên',
    memberBetRecord: 'Lịch sử cược thành viên',
    memberBetRecordByPlatform: 'Tóm tắt cược theo nền tảng',
    memberCommission: 'Hoa hồng thành viên',
    memberConsolidateReport: 'Báo cáo hợp nhất thành viên',
    memberDetailDeposit: 'Tổng số tiền gửi',
    memberDetailDepositCount: 'Tổng số lần gửi tiền',
    memberDetailPrivilege: 'Tổng số đặc quyền',
    memberDetailWithdraw: 'Tổng số tiền rút',
    memberFeedbackType: 'Loại phản hồi thành viên',
    memberId: 'ID thành viên',
    memberInfo: 'Thông tin thành viên',
    memberList: 'Danh sách thành viên',
    memberMessage: 'Tin nhắn thành viên',
    memberName: 'Tên thành viên',
    memberPlatformAction: 'Hành động (đăng ký/xóa thủ công)',
    memberPlatformUpdate: 'Đồng bộ hóa mật khẩu',
    memberPrivilegeBlacklist: 'Danh sách đen đặc quyền thành viên',
    memberProfitDownlineProfitUnsettleCommission:
      'Lợi nhuận thành viên / Lợi nhuận tuyến dưới / Hoa hồng chưa giải quyết',
    memberReport: 'Báo cáo thành viên',
    memberType: 'Loại thành viên',
    memberWithdrawLog: 'Nhật ký rút tiền thành viên',
    menuGame: 'Thứ tự trò chơi trong menu',
    menuIcon: 'Biểu tượng menu',
    menuTitle: 'Tiêu đề menu',
    menuType: 'Loại menu',
    menuOnlineUser: 'Người dùng trực tuyến',
    message: 'Tin nhắn',
    min: 'Lợi nhuận tối thiểu',
    minAmount: 'Số tiền tối thiểu',
    max: 'Lợi nhuận tối đa',
    maxAmount: 'Số tiền tối đa',
    minno: 'Nhập giá trị tối thiểu',
    maxno: 'Nhập giá trị tối đa',
    memberRemark: 'Ghi chú thành viên',
    minActiveMemberCount: 'Số lượng thành viên hoạt động tối thiểu',
    minBalance: 'Số dư tối thiểu',
    minDeposit: 'Số tiền gửi tối thiểu',
    minTotalCommission: 'Tổng hoa hồng tối thiểu',
    minWithdrawAmount: 'Số tiền rút tối thiểu',
    mobile: 'Di động',
    mobileImage: 'Hình ảnh di động',
    mobileBackgroundImage: 'Hình nền di động',
    mobileBanner: 'Biểu ngữ di động',
    mobileMessage: 'Tin nhắn di động',
    mobileWay: 'Di động',
    mode: 'chế độ',
    moneyChange: 'Di chuyển tiền',
    month: 'Tháng',
    mqLogProcessWithTitle: 'tiêu đề (SummaryDoris)',
    mqLogProcessTopic: 'đề tài',
    mqLogProcessTimestamp: 'Dấu thời gian hiện tại',
    mqLogTimestampWithTimezone: 'dấu thời gian của trang web',
    name: 'Tên',
    needRegister: 'Cần đăng ký',
    needSpecify: 'Cần chỉ định',
    netProfit: 'Lợi nhuận ròng',
    netPhone: 'Cài đặt cuộc gọi điện thoại',
    newMember: 'Thành viên mới',
    newMemberCount: 'Số lượng thành viên mới',
    newPassword: 'Mật khẩu mới',
    nextActivationTime: 'Thời gian kích hoạt tiếp theo',
    nextGetBetEndTime: 'Thời gian kết thúc cược tiếp theo',
    nextGetBetIndex: 'Chỉ số cược tiếp theo',
    nextGetBetPage: 'Trang cược tiếp theo',
    nextGetBetStartTime: 'Thời gian bắt đầu cược tiếp theo',
    nextLevel: 'Cấp độ tiếp theo',
    nextLevelPoint: 'Điểm cấp độ tiếp theo',
    nextStep: 'Bước tiếp theo',
    no: 'Không',
    noData: 'Không có dữ liệu',
    noDeposit: 'Không có tiền gửi',
    noLimit: 'Không giới hạn',
    noOfDeduction: 'Số lần khấu trừ',
    noOfDepositTimes: 'Số lần gửi tiền',
    noOfPrivilegeClaimed: 'Số đặc quyền đã yêu cầu',
    noOfReimbursement: 'Số lần bồi hoàn',
    noOfWinners: 'Không có người thắng cuộc',
    noOfWithdrawTimes: 'Số lần rút tiền',
    notBinded: 'Chưa được liên kết',
    notRegister: 'Chưa đăng ký',
    notice: 'Thông báo',
    notRead: 'Chưa đọc',
    notReplied: 'Chưa trả lời',
    number: 'Số',
    oneTimeSpin: 'Một lần quay',
    onlyDeposit: 'Chỉ hiển thị lịch sử nạp tiền',
    onlyFake: 'Chỉ hiển thị hồ sơ không phải thành viên',
    onlyReal: 'Chỉ hiển thị hồ sơ thành viên',
    open: 'Mở',
    operate: 'Thao tác',
    operator: 'Người vận hành',
    operateTime: 'Thời gian thao tác',
    operationTime: 'Thời gian vận hành',
    operationType: 'Loại hoạt động',
    orderBy: 'Sắp xếp theo',
    orderNo: 'Số đơn hàng',
    orderTrackingNo: 'Số theo dõi đơn hàng',
    outerSetting: 'Cài đặt bánh xe bên ngoài',
    param: 'Tham số',
    parentSite: 'Trang chính',
    password: 'Mật khẩu',
    pause: 'Tạm dừng',
    pay: 'Thanh toán',
    payBy: 'Phương thức thanh toán',
    payMessage: 'Thông báo thanh toán',
    payMessageMobile: 'Thông báo thanh toán di động',
    payResultType: 'Loại kết quả thanh toán',
    payTime: 'Thời gian thanh toán',
    payType: 'Loại thanh toán',
    payTypeCode: 'Mã loại thanh toán',
    payTypeName: 'Tên loại thanh toán',
    payment: 'Thanh toán',
    paymentArea: 'Khu vực thanh toán',
    paymentBy: 'Người thanh toán',
    paymentCard: 'Thẻ thanh toán',
    paymentChannel: 'Kênh thanh toán',
    paymentDate: 'Ngày thanh toán',
    paymentDisplay: 'Hiển thị thanh toán',
    paymentFee: 'Phí thanh toán',
    paymentMethod: 'Phương thức thanh toán',
    paymentName: 'Tên thanh toán',
    paymentOnGoing: 'Đang tiến hành thanh toán',
    paymentType: 'Loại thanh toán',
    payout: 'Thanh toán',
    payoutTotal: 'Tổng tiền trả thưởng',
    return_ratio: 'Tỷ lệ hoàn trả',
    return_ratio_total: 'Tổng tỷ lệ hoàn trả',
    pcWay: 'Máy tính',
    pending: 'Đang chờ xử lý',
    pendingTransaction: 'Giao dịch đang chờ xử lý',
    permission: 'Quyền',
    permissionAssignment: 'Phân quyền',
    pgroup: 'Nhóm đặc quyền',
    photo: 'Ảnh',
    piggyBankRecords: 'Hồ sơ ống tiết kiệm',
    piggyBankSettings: 'Cài đặt ống tiết kiệm',
    place: 'Địa điểm',
    placeTime: 'Tiết kiệm thời gian',
    planDays: 'Ngày lập kế hoạch',
    planOdds: 'Kế hoạch tỷ lệ',
    planLimitMin: 'Giới hạn tối thiểu của kế hoạch',
    planLimitMax: 'Giới hạn tối đa của gói',
    planTimeLimit: 'Giới hạn thời gian lập kế hoạch',
    platform: 'Nền tảng',
    platformAccount: 'Tài khoản nền tảng',
    platformId: 'ID nền tảng',
    platformBalance: 'Số dư nền tảng',
    platformCode: 'Mã nền tảng',
    platformFee: 'Phí nền tảng',
    platformFundTransfer: 'Chuyển tiền nền tảng',
    platformName: 'Tên nền tảng',
    playerName: 'Tên người chơi',
    pleaseChoose: 'Vui lòng chọn',
    pleaseInput: 'Vui lòng nhập',
    poolAmount: 'Số tiền trong quỹ',
    previousLevel: 'Cấp độ trước',
    previousLevelName: 'Tên cấp độ trước',
    previousPlace: 'Địa điểm trước',
    privilege: 'Đặc quyền',
    privilegeCode: 'Mã đặc quyền',
    privilegeId: 'ID đặc quyền',
    privilegeName: 'Tên đặc quyền',
    privilegeRedeemed: 'Đặc quyền đã đổi',
    privilegeSerialNo: 'Số seri đặc quyền',
    privilegeType: 'Loại đặc quyền',
    probability: 'Xác suất',
    profit: 'Lợi nhuận',
    profitpositive: 'Lợi nhuận dương',
    profitnegative: 'Lợi nhuận âm',
    promo: 'Khuyến mãi',
    promoApplication: 'Áp dụng khuyến mãi',
    promoCode: 'Mã khuyến mãi',
    promoPages: 'Trang khuyến mãi',
    promoStyle: 'Phong cách khuyến mãi',
    promoType: 'Loại khuyến mãi',
    queryNumber: 'Số lượng truy vấn',
    queryOption: 'Tùy chọn truy vấn',
    queryRestriction: 'Giới hạn truy vấn',
    question: 'Câu hỏi',
    questionOne: 'Câu hỏi 1',
    questionTwo: 'Câu hỏi 2',
    questionThree: 'Câu hỏi 3',
    quizDetails: 'Chi tiết bài kiểm tra',
    quizTime: 'Thời gian bài kiểm tra',
    quizTitle: 'Tiêu đề bài kiểm tra',
    randomMember: 'Thành viên ngẫu nhiên',
    rate: 'Tỷ lệ',
    realName: 'Tên thật',
    englishName: 'Tên tiếng Anh',
    realVirtualVotes: 'Bỏ phiếu thực tế + Bỏ phiếu ảo',
    reason: 'Lý do',
    reasonType: 'Loại lý do',
    reasonTemplate: 'Mẫu lý do',
    rebate: 'Hoàn tiền',
    rebateDistributeTime: 'Thời gian phân phối hoàn tiền',
    rebateLevel: 'Cấp độ hoàn tiền',
    rebatePercentage: 'Tỷ lệ hoàn tiền',
    regEndDate: 'Ngày kết thúc đăng ký',
    regStartDate: 'Ngày bắt đầu đăng ký',
    regTime: 'Thời gian đăng ký',
    redPacketMinDayDeposit: 'Số tiền gửi tối thiểu hàng ngày để nhận bao lì xì',
    redPacketMinTotalDeposit: 'Tổng số tiền gửi tối thiểu để nhận bao lì xì',
    redPacketMinDayBetAmount:
      'Số tiền cược tối thiểu hàng ngày để nhận bao lì xì',
    rule: 'Quy tắc',
    maxRebate: 'Hoàn tiền tối đa',
    maxBalance: 'Số dư tối đa',
    nickName: 'Biệt danh',
    rebateAmount: 'Số tiền hoàn tiền',
    receiveTime: 'Thời gian nhận',
    receiveType: 'Loại nhận',
    recipient: 'Người nhận',
    recipientTelephone: 'Số điện thoại người nhận',
    records: 'Hồ sơ',
    recordDate: 'Ngày ghi chép',
    recordTime: 'Thời gian ghi chép',
    redeemCash: 'Đổi tiền mặt',
    redeemedBy: 'Đã đổi bởi',
    redeemCode: 'Mã đổi thưởng',
    redeemPoints: 'Đổi điểm',
    redirect: 'Chuyển hướng',
    reenterPassword: 'Nhập lại mật khẩu',
    referrer: 'Người giới thiệu',
    referFriendRebateDetails: 'Chi tiết hoàn tiền khi giới thiệu bạn bè',
    referFriendRebateRecords: 'Hồ sơ hoàn tiền khi giới thiệu bạn bè',
    register: 'Đăng ký',
    registered: 'Đã đăng ký',
    registerAddress: 'Địa chỉ đăng ký',
    registerCount: 'Số lần đăng ký',
    registerDevice: 'Thiết bị đăng ký',
    registerHost: 'Máy chủ đăng ký',
    registerIp: 'IP đăng ký',
    registerMember: 'Thành viên đăng ký',
    registerTime: 'Thời gian đăng ký',
    registrationDate: 'Ngày đăng ký',
    referredFriends: 'Bạn bè đã giới thiệu',
    referFriendRecord: 'Hồ sơ giới thiệu bạn bè',
    remark: 'Ghi chú',
    remove: 'Xóa bỏ',
    reply: 'Trả lời',
    replyTitle: 'Tiêu đề trả lời',
    replyContent: 'Nội dung trả lời',
    requestUrl: 'URL yêu cầu',
    requestParam: 'Tham số yêu cầu',
    responseBody: 'Nội dung phản hồi',
    resend: 'Gửi lại',
    reset: 'Đặt lại',
    resettleBbDacha: 'Đặt lại BB Dacha',
    resettleGameQuiz: 'Đặt lại bài kiểm tra trò chơi',
    resettleMatch: 'Đặt lại trận đấu',
    result: 'Kết quả',
    resultDate: 'Ngày kết quả',
    resultNumber: 'Số lượng kết quả',
    resultTime: 'Thời gian kết quả',
    resume: 'Tiếp tục',
    revenueShare: 'Chia sẻ doanh thu',
    review: 'Đánh giá',
    reviewby: 'Đánh giá bởi',
    reviewno: 'Không có đánh giá',
    reviewsuccess: 'Chính xác',
    reviewstatus: 'Trạng thái đánh giá',
    reviewfail: 'Không chính xác',
    rewardGroup: 'Nhóm thưởng',
    rewardGroupName: 'Tên nhóm',
    rewardGroupDownlineQuota: 'Hạn ngạch nhóm tuyến dưới',
    riskControl: 'Kiểm soát rủi ro',
    riskLevel: 'Mức độ rủi ro',
    riskPaymentChannel: 'Kênh thanh toán rủi ro',
    role: 'Vai trò',
    roleDetails: 'Chi tiết vai trò',
    roleList: 'Danh sách vai trò',
    roleName: 'Tên vai trò',
    roleToCopy: 'Vai trò cần sao chép',
    rollover: 'Rollover',
    rolloverAmount: 'Số tiền rollover',
    rolloverRecord: 'Hồ sơ rollover',
    gameTypeRollover: 'Rollover loại trò chơi',
    router: 'Router',
    round: 'Vòng quay miễn phí',
    rules: 'Quy tắc',
    ruleParam: 'Tham số quy tắc',
    ruleType: 'Loại quy tắc',
    run: 'Chạy',
    save: 'Lưu',
    saveAsNewPayment: 'Lưu Như Thanh Toán Mới',
    scheduleTime: 'Thời Gian Lịch Trình',
    schemaName: 'Tên Schema',
    search: 'Tìm kiếm',
    searchCondition: 'Điều Kiện Tìm Kiếm',
    securityQuestion: 'Câu Hỏi Bảo Mật',
    secondTicket: 'Vé Thứ Hai (Cược/Gửi Tiền)',
    selectedMembers: 'Thành Viên Được Chọn',
    sendTime: 'Thời Gian Gửi',
    sendSuccess: 'Gửi Thành Công',
    sendFail: 'Gửi Thất Bại',
    select: 'Chọn',
    selectNodeAddTo: 'Chọn Node để Thêm',
    selected: 'Đã Chọn',
    selectedNumber: 'Số Đã Chọn',
    selectedTeam: 'Đội Đã Chọn',
    send: 'Gửi',
    sequence: 'Thứ Tự',
    serialNo: 'Số Seri',
    settle: 'Thanh Toán',
    settleAmount: 'Số Tiền Thanh Toán',
    settleGameMatch: 'Thanh Toán Trận Đấu',
    settlement: 'Thanh Toán',
    settleTime: 'Thời Gian Thanh Toán',
    settleView: 'Xem',
    settlePay: 'Thanh Toán',
    settleEdit: 'Chỉnh Sửa',
    show: 'Hiển Thị',
    showAll: 'Hiển Thị Tất Cả',
    sid: 'SID',
    site: 'Trang Web',
    siteDetail: 'Chi tiết trang web',
    siteInfo: 'Thông tin trang web',
    siteCode: 'Mã Trang Web',
    siteList: 'Danh Sách Trang Web',
    siteName: 'Tên Trang Web',
    siteType: 'Site Type',
    sn: 'Số Seri',
    smsPhone: 'Số Điện Thoại SMS',
    smsContent: 'Nội Dung SMS',
    smsStatus: 'Trạng Thái SMS',
    smsSendTime: 'Thời Gian Gửi SMS',
    smsType: 'Loại SMS',
    sorting: 'Sắp Xếp',
    sortType: 'Loại Sắp Xếp',
    sourceType: 'Loại Nguồn',
    sportBetResult: 'Kết Quả Cược Thể Thao',
    stage: 'Giai Đoạn',
    state: 'Trạng Thái',
    startCopy: 'Bắt Đầu Sao Chép',
    startMatch: 'Bắt Đầu Trận Đấu',
    startUpdate: 'Bắt Đầu Cập Nhật',
    startDate: 'Ngày Bắt Đầu',
    startTime: 'Giờ Bắt Đầu',
    status: 'Trạng Thái',
    steps: 'Bước',
    stepBonus: 'Thưởng Bước',
    stopAfterFailure: 'Ngừng Sau Khi Thất Bại',
    subject: 'Tiêu Đề',
    subSiteCount: 'Số Lượng Trang Web Con',
    subtotal: 'Tổng Phụ',
    success: 'Thành Công',
    successRate: 'Tỷ Lệ Thành Công',
    successAmounntRate: 'Tỷ Lệ Số Tiền Thành Công',
    successfullyExport: 'Xuất Thành Công',
    summary: 'bản tóm tắt',
    summaryAdjustment: 'Điều Chỉnh',
    summaryBonus: 'Thưởng',
    summaryCompanyWinLoss: 'Lợi Nhuận/Thua Của Công Ty',
    summaryProfit: 'Lợi Nhuận',
    summaryReport: 'Báo Cáo Tóm Tắt',
    summaryTable: 'Bảng Tóm Tắt',
    summaryTotalBet: 'Tổng Số Cược',
    superiorAffiliateInfo: 'Thông Tin Đối Tác Liên Kết Cấp Cao',
    superiorAffiliateName: 'Tên Đối Tác Liên Kết Cấp Cao',
    superiorAffiliateCode: 'Mã Đối Tác Liên Kết Cấp Cao',
    superiorAffiliateLevel: 'Cấp Độ Đối Tác Liên Kết Cấp Cao',
    superiorCategory: 'Danh Mục Cấp Cao',
    superiorAffiliatePaymentChannel:
      'Kênh Thanh Toán Của Đối Tác Liên Kết Cấp Cao',
    supplement: 'Bổ Sung',
    supplementDeposit: 'Bổ Sung Tiền Gửi',
    supportType: 'Loại Hỗ Trợ',
    sureWin: 'Chắc Chắn Chiến Thắng',
    sureWinBonus: 'Thưởng Chiến Thắng Chắc Chắn',
    sureWinMinSpin: 'Số Lượt Quay Tối Thiểu Cho Chiến Thắng Chắc Chắn',
    suspend: 'Tạm Dừng',
    switch: 'Chuyển Đổi',
    sync: 'Đồng Bộ Hóa',
    szCnyControl: 'Kiểm Soát Rút SZCny',
    switchPayment: 'Chuyển Đổi Thanh Toán',
    systemPayment: 'Thanh Toán Hệ Thống',
    targetType: 'Loại Mục Tiêu',
    targetId: 'ID Mục Tiêu',
    targetName: 'Tên Mục Tiêu',
    teamA: 'Đội A',
    teamB: 'Đội B',
    teamC: 'Đội C',
    teamD: 'Đội D',
    teamE: 'Đội E',
    teamF: 'Đội F',
    team16: '16 Đội',
    team8: '8 Đội',
    team4: '4 Đội',
    team2: 'Vô Địch',
    teamGroup: 'Nhóm Đội',
    teamName: 'Tên Đội',
    teamNameEn: 'Tên Đội (Tiếng Anh)',
    teamNameLocal: 'Tên Đội (Địa Phương)',
    teamOne: 'Đội 1',
    teamOneIcon: 'Biểu Tượng Đội 1',
    teamTwo: 'Đội 2',
    teamTwoIcon: 'Biểu Tượng Đội 2',
    teamBackgroundImage: 'Hình nền đội',
    teamBackgroundImageDark: 'đội hình nền tối',
    telephone: 'Điện Thoại',
    thirtyDaysdw: '30 Ngày Gửi - Rút',
    thirdParty: 'Bên Thứ Ba',
    thirdPartyName: 'Tên Bên Thứ Ba',
    thirdPartyTotalBet: 'Tổng Số Cược Bên Thứ Ba',
    thirdPartyTotalPayout: 'Tổng Số Tiền Thanh Toán Bên Thứ Ba',
    thirdPartyTotalWin: 'Tổng Số Tiền Thắng Bên Thứ Ba',
    thirdSerialNo: 'Số Seri Thứ Ba',
    thirdTicket: 'Vé Thứ Ba (Cược/Gửi Tiền)',
    thisMonth: 'Tháng Này',
    thisWeek: 'Tuần Này',
    ticketSetting: 'Cài Đặt Vé',
    tips: 'Mẹo',
    title: 'Tiêu Đề',
    toApplying: 'Đang Áp Dụng',
    toBeforePaid: 'Trước Khi Thanh Toán',
    toBePaid: 'Chờ Thanh Toán',
    toChecking: 'Đang Kiểm Tra',
    today: 'Hôm Nay',
    toPay: 'Thanh Toán',
    toPaymentOnGoing: 'Đang Thanh Toán',
    topic: 'Chủ Đề',
    toreview: 'Đang Đánh Giá',
    toSuspend: 'Dự Định Tạm Dừng',
    total: 'Tổng Cộng',
    totalActualRate: 'Tổng số tiền lãi',
    totalAdjustment: 'Tổng Điều Chỉnh',
    totalBalance: 'Tổng Số Dư',
    totalBet: 'Tổng Số Cược',
    totalBetAmount: 'Tổng Số Tiền Cược',
    totalBetCount: 'Tổng Số Lượt Cược',
    totalBetMemberCount: 'Tổng Số Thành Viên Cược',
    totalBetRecords: 'Tổng Số Hồ Sơ Cược',
    totalBonus: 'Tổng Số Thưởng',
    totalCommission: 'Tổng Số Hoa Hồng',
    totalCommissionProfit: 'Tổng Số Lợi Nhuận Từ Hoa Hồng',
    totalCompanyProfit: 'Tổng Lợi Nhuận Công Ty',
    totalDeductAmount: 'Tổng Số Tiền Trừ',
    totalDeposit: 'Tổng Số Tiền Gửi',
    totalDepositAmount: 'Tổng Số Tiền Gửi',
    totalDepositCount: 'Tổng Số Lượt Gửi',
    totalDepositMemberCount: 'Tổng Số Thành Viên Gửi',
    totalDepositedAmount: 'Tổng Số Tiền Gửi',
    totalDepositNumber: 'Tổng Số Thành Viên Gửi',
    totalDownlineAffiliates: 'Tổng Số Đại Lý Cấp Dưới',
    totalDownlineMembers: 'Tổng Số Thành Viên Cấp Dưới',
    totalFailDeposit: 'Tổng Số Giao Dịch Gửi Thất Bại',
    totalFailDepositAmount: 'Tổng Số Tiền Gửi Thất Bại',
    totalFailWithdraw: 'Tổng Số Giao Dịch Rút Thất Bại',
    totalFailWithdrawAmount: 'Tổng Số Tiền Rút Thất Bại',
    totalFirstDeposit: 'Tổng Số Tiền Gửi Lần Đầu',
    totalMemberDepositAmount: 'Tổng Số Tiền Gửi Thành Viên',
    totalMemberDepositCount: 'Tổng Số Lượt Gửi Của Thành Viên',
    totalNoOfDeposits: 'Tổng Số Lượt Gửi',
    totalNoOfWithdrawals: 'Tổng Số Lượt Rút',
    totalPayout: 'Tổng Số Thanh Toán',
    totalPlaceAmount: 'Tổng số tiền gửi',
    totalPlaceMemberCount: 'Tổng số người',
    totalPrivilegeAmount: 'Tổng Số Tiền Ưu Đãi',
    totalPrivilegeClaimAmount: 'Tổng Số Tiền Yêu Cầu Ưu Đãi',
    totalPromo: 'Tổng Số Khuyến Mãi',
    totalProfit: 'Tổng Lợi Nhuận',
    totalRebateAmount: 'Tổng Số Tiền Hoàn Trả',
    totalRedeem: 'Tổng Số Rút',
    totalRegister: 'Tổng Số Đăng Ký',
    totalRegisterCount: 'Tổng Số Lượt Đăng Ký',
    totalReimburseAmount: 'Tổng Số Tiền Hoàn Lại',
    totalSuccessDeposit: 'Tổng Số Giao Dịch Gửi Thành Công',
    totalSuccessDepositAmount: 'Tổng Số Tiền Gửi Thành Công',
    totalSuccessDepositPercentage: 'Tỷ Lệ Gửi Thành Công',
    totalSuccessWithdraw: 'Tổng Số Giao Dịch Rút Thành Công',
    totalSuccessWithdrawAmount: 'Tổng Số Tiền Rút Thành Công',
    totalTime: 'Tổng Thời Gian',
    totalTransferIn: 'Tổng Số Tiền Gửi',
    totalTransferOut: 'Tổng Số Tiền Rút',
    toUnderReview: 'Đang Đánh Giá',
    totalValidBet: 'Tổng Số Cược Hợp Lệ',
    totalWithdraw: 'Tổng Số Tiền Rút',
    totalWithdrawAmount: 'Tổng Số Tiền Rút',
    totalWithdrawCount: 'Tổng Số Lượt Rút',
    totalWithdrawnAmount: 'Tổng Số Tiền Rút',
    transaction: 'Giao Dịch',
    transactionStatus: 'Trạng Thái Giao Dịch',
    transactionTime: 'Thời Gian Giao Dịch',
    transactionId: 'ID Giao Dịch',
    transferDate: 'Ngày Chuyển Khoản',
    transferId: 'ID Chuyển Khoản',
    transferIn: 'Chuyển Tiền Vào',
    transferOut: 'Chuyển Tiền Ra',
    transferTime: 'Thời Gian Chuyển Khoản',
    transferType: 'Loại Chuyển Khoản',
    triggerType: 'Loại Kích Hoạt',
    turnoverMultiple: 'Lần Quay Vòng',
    txnId: 'ID Giao Dịch',
    txnTime: 'Thời Gian Giao Dịch',
    type: 'Loại',
    uefaMatchRecord: 'Kết Quả Trận Đấu UEFA',
    uefaMatchSetting: 'Cài Đặt Trận Đấu UEFA',
    uefaTeamRecord: 'Kết Quả Đội Bóng UEFA',
    uefaTeamSetting: 'Cài Đặt Đội Bóng UEFA',
    undefined: 'Chưa Được Xác Định',
    underMaintenance: 'Đang Bảo Trì',
    underReview: 'Đang Đánh Giá',
    unlockMember: 'Mở Khóa Thành Viên',
    unlockSetting: 'Mở Khóa Cài Đặt',
    unlockUser: 'Mở Khóa Người Dùng',
    url: 'URL',
    unsettleCommission: 'Chưa Thanh Toán Hoa Hồng',
    update: 'Cập Nhật',
    updateAlias: 'Cập Nhật Bí Danh',
    updateAwards: 'Cập Nhật Giải Thưởng',
    updateBy: 'Cập Nhật Bởi',
    updateCommissionModel: 'Cập Nhật Mô Hình Hoa Hồng',
    updateCommissionRate: 'Cập Nhật Tỷ Lệ Hoa Hồng',
    updateFinancialLevel: 'Cập Nhật Cấp Độ Tài Chính',
    updateMaintenanceTime: 'Cập Nhật Thời Gian Bảo Trì',
    updatePassword: 'Cập Nhật Mật Khẩu',
    updatePlatformFee: 'Cập Nhật Phí Nền Tảng',
    updatePaymentFee: 'Cập Nhật Phí Thanh Toán',
    updateRiskLevel: 'Cập Nhật Cấp Độ Rủi Ro',
    updateStatus: 'Cập Nhật Trạng Thái',
    updateTime: 'Thời Gian Cập Nhật',
    updateVIPLevel: 'Cập Nhật Cấp Độ VIP',
    upgradeToCheck: 'Nâng Cấp Lên Kiểm Tra',
    upgradeToPaymentOnGoing: 'Nâng Cấp Lên Đang Thanh Toán',
    upLineLoginName: 'Tên Đăng Nhập Upline',
    upload: 'Tải Lên',
    upperName: 'Tên Upline',
    usdtControl: 'Kiểm Soát Rút USDT',
    usdtDepositAmount: 'Số tiền gửi tiền kỹ thuật số',
    usdtDepositCount: 'Số lượng người gửi tiền kỹ thuật số',
    username: 'Tên Người Dùng',
    userType: 'Loại Người Dùng',
    validate: 'Xác Nhận',
    validity: 'Hiệu Lực',
    validBet: 'Cược Hợp Lệ',
    validBetPerSpin: 'Cược Hợp Lệ Mỗi Lượt Quay',
    value: 'Giá Trị',
    vcallName: 'Số Điện Thoại VoIP',
    view: 'Xem',
    viewChoice: 'Xem Lựa Chọn Khảo Sát',
    viewDetails: 'Xem Chi Tiết',
    viewLog: 'Xem Log',
    viewLoginName: 'Xem Tên Đăng Nhập',
    viewPermission: 'Quyền xem',
    vipLevel: 'Cấp Độ VIP',
    vipRebateDetails: 'Chi Tiết Hoàn Trả VIP',
    vipRebateRecord: 'Hồ Sơ Hoàn Trả VIP',
    vipRules: 'Quy Tắc VIP',
    vipWheelSettings: 'Cài Đặt Bánh Xe VIP',
    vipWheelRecords: 'Hồ Sơ Bánh Xe VIP',
    virtualVotes: 'Phiếu Bầu Ảo',
    visitNumber: 'Số Lượt Ghé Thăm',
    voteTime: 'Thời Gian Bầu Chọn',
    walletType: 'Loại ví',
    way: 'Cách thức',
    web: 'Web',
    webMessage: 'Tin nhắn web',
    winnerTeam: 'Đội thắng',
    withdraw: 'Rút tiền',
    withdrawableBalance: 'Số dư có thể rút',
    withdrawArea: 'Khu vực rút tiền',
    withdrawChannel: 'Kênh rút tiền',
    withdrawExchangeRate: 'Tỷ giá rút tiền',
    withdrawalFailureCause: 'Nguyên nhân rút tiền thất bại',
    withdrawalFailureType: 'Loại rút tiền thất bại',
    withdrawAmount: 'Số tiền rút',
    withdrawCard: 'Thẻ rút tiền',
    withdrawCode: 'Mã rút tiền',
    withdrawCount: 'Số lần rút',
    withdrawDate: 'Ngày rút',
    withdrawMemberCount: 'Số thành viên rút tiền',
    withdrawName: 'Tên rút tiền',
    withdrawPayoutBonus: 'Thanh toán/Rút/Thưởng/Điều chỉnh',
    withdrawPlatform: 'Nền tảng rút tiền',
    withdrawPlatformList: 'Danh sách nền tảng rút tiền',
    withdrawPlatformName: 'Tên nền tảng rút tiền',
    withdrawStatus: 'Trạng thái rút tiền',
    yes: 'Có',
    yesterday: 'Hôm qua',
    tigerCardType: 'Loại thẻ tiger',
    tigerCardOpeType: 'Loại hoạt động thẻ tiger',
    tigerCardSource: 'Nguồn/Nhắm mục tiêu',
    tigerCardBeforeCount: 'Số lượng trước đó của thẻ tiger',
    tigerCardRealCount: 'Số thẻ tiger thực tế',
    tigerCardVirtualCount: 'Số thẻ tiger ảo',
    tigerCardPeriod: 'Khoảng thời gian',
    tigerCardTime: 'Thời gian xổ số',
    tigerCardBonusTime: 'Thời gian nhận thưởng',
    timeType: 'Loại thời gian',
    count: 'Số lượng',
    host: 'Máy chủ',
    port: 'Cổng',
    user: 'Người dùng',
    from: 'Từ',
    addEmailSetting: 'Thêm cài đặt email',
    editEmailSetting: 'Chỉnh sửa cài đặt email',
    signName: 'Tên chữ ký',
    secretId: 'ID bí mật',
    secretKey: 'Khóa bí mật',
    setUndefined: 'Đặt là không xác định',
    appId: 'ID ứng dụng',
    templateId: 'ID mẫu',
    addSmsSetting: 'Thêm cài đặt SMS',
    editSmsSetting: 'Chỉnh sửa cài đặt SMS',
    requestExportToExcel: 'Yêu cầu xuất ra Excel',
    downloadManagerRecord: 'Tải xuống bản ghi quản lý',
    downloadExportedExcel: 'Tải xuống Excel đã xuất',
    redPacketAmountAfterReachingLimit: 'Số tiền bao lì xì sau khi đạt giới hạn',
    requestBy: 'Yêu cầu bởi',
    requestTime: 'Thời gian yêu cầu',
    progress: 'Tiến trình',
    download: 'Tải xuống',
    updateProgress: 'Cập nhật tiến trình',
    add_new: 'Thêm mới',
    date_added: 'Ngày được thêm',
    please_add_at_least_one_date_time:
      'Vui lòng thêm ít nhất một ngày và một thời gian.',
    multiple_rain_added: 'Thêm nhiều khoảng thời gian mưa hàng ngày.',
    date_already_exists: 'Ngày đã tồn tại.',
    time_already_exists: 'Phạm vi thời gian đã tồn tại.',
    add_single_daterange: 'Thêm phạm vi ngày đơn',
    adde_multiple_daterange: 'Thêm phạm vi ngày nhiều',
    add_single_date_steps:
      'Bước: Chọn phạm vi ngày và thời gian, sau đó nhấp vào "Thêm".',
    add_multiple_date_steps:
      'Bước: Chọn tất cả các kết hợp của phạm vi ngày và thời gian, sau đó nhấp vào "Thêm phạm vi ngày" và "Thêm thời gian", sau đó nhấp vào "Thêm" để thêm chúng.',
    pick_date: 'Chọn ngày',
    pick_start_date: 'Chọn ngày/giờ bắt đầu',
    pick_end_date: 'Chọn ngày/giờ kết thúc',
    please_select_datetime: 'Vui lòng chọn ngày bắt đầu và ngày kết thúc',
    add_date: 'Thêm ngày',
    add_time: 'Thêm thời gian',
    dates: 'Ngày:',
    timeranges: 'Phạm vi thời gian:',
    timeZone: 'Múi giờ',
    image: 'Hình ảnh',
    imageName: 'Tên hình ảnh',
    addImage: 'Thêm hình ảnh',
    editImage: 'Chỉnh sửa hình ảnh',
    browse: 'Duyệt',
    imageSite: 'Trang web hình ảnh',
    imageRemark: 'Chú thích hình ảnh',
    selectedImage: 'Hình ảnh đã chọn',
    poster: 'Poster',
    posterType: 'Loại poster',
    createConfig: 'Tạo cấu hình',
    defaultConfigHint: 'mặc định',
    defaultConfigSearchBarHint: 'Tìm kiếm ',
    configCode: 'Mã cấu hình',
    configValue: 'Giá trị cấu hình',
    configDescribes: 'Mô tả cấu hình',
    s3Url: 'URL S3',
    allSites: 'Tất cả các trang web',
    allSource: 'Tất cả các loại nguồn',
    rank: 'Xếp hạng',
    processResult: 'Kết quả xử lý',
    winner: 'Người chiến thắng',
    teamIcon: 'Biểu tượng đội',
    other: 'Khác',
    dailyDetail: 'Chi tiết hàng ngày',
    dailySummary: 'Tóm tắt hàng ngày',
    officialWebData: 'Dữ liệu web chính thức',
    packageWebData: 'Dữ liệu web gói',
    payoutAmount: 'Số tiền thanh toán',
    indBet: 'Số tiền cược cá nhân',
    indProfit: 'Lợi nhuận cá nhân',
    memberDepositCount: 'Số lần gửi tiền thành viên',
    indBonusAmount: 'Số tiền thưởng cá nhân',
    indRebateAmount: 'Số tiền hoàn trả cá nhân',
    indAdjustAmount: 'Số tiền điều chỉnh cá nhân',
    grossProfit: 'Lợi nhuận gộp',
    football: 'Bóng đá',
    basketball: 'Bóng rổ',
    esport: 'Thể thao điện tử',
    competitionType: 'Loại cuộc thi',
    competitionName: 'Tên cuộc thi',
    competitionTime: 'Thời gian cuộc thi',
    addCompetition: 'Thêm cuộc thi',
    editCompetition: 'Chỉnh sửa cuộc thi',
    addCompetitionCode: 'Thêm mã cuộc thi',
    editCompetitionCode: 'Chỉnh sửa mã cuộc thi',
    addMemberTreasureKey: 'Thêm chìa khóa bảo vệ thành viên',
    deductMemberTreasureKey: 'Trừ chìa khóa bảo vệ thành viên',
    keyQuantity: 'Số lượng chìa khóa',
    keyQuantityBefore: 'Số lượng chìa khóa trước đó',
    keyQuantityAfter: 'Số lượng chìa khóa sau',
    checkInDate: 'Ngày nhận phòng',
    consecutiveDay: 'Ngày liên tiếp',
    summoner: 'Người triệu hồi',
    memberSummon: 'Triệu hồi viên',
    summonBonus: 'Thưởng triệu hồi',
    depositBonus: 'Thưởng gửi tiền',
    depositDayCount: 'Số ngày gửi tiền',
    redirectUrlApp: 'URL chuyển hướng ứng dụng',
    redirectUrlWeb: 'URL chuyển hướng web',
    redirectUrlH5: 'URL chuyển hướng H5',
    popUpDurationSeconds: 'Thời gian hiển thị popup (giây)',
    popUpTime: 'Thời gian hiển thị popup',
    notificationType: 'Loại thông báo',
    notificationContent: 'Nội dung thông báo',
    unbind: 'Ngắt kết nối',
    winloss: 'Thắng/Thua',
    profit2: 'Lợi nhuận',
    batchDistributeRebate: 'Phân phối hoàn trả hàng loạt',
    distribute: 'Phân phối',
    affiliateBetRebateRecord: 'Hồ sơ hoàn trả cược liên kết',
    affiliateBetRebateDetails: 'Chi tiết hoàn trả cược liên kết',
    adjustRebateAmount: 'Điều chỉnh số tiền hoàn trả',
    whitelist: 'Danh sách trắng',
    blacklist: 'Danh sách đen',
    accessType: 'Loại truy cập',
    domainType: 'Loại tên miền',
    valueType: 'Loại giá trị',
    ipList: 'Danh sách IP',
    ipRange: 'Phạm vi IP',
    countryCode: 'Mã quốc gia',
    lower: 'Thấp nhất',
    upper: 'Cao nhất',
    updateAffiliateLevel: 'Cập nhật cấp độ liên kết',
    memberMoney: 'Tiền thành viên',
    memberPoint: 'Điểm thành viên',
    eggs: 'Trứng',
    memberInquiry: 'Yêu cầu thành viên',
    depositApplication: 'Đơn gửi tiền',
    withdrawApplication: 'Đơn rút tiền',
    affiliateApplication: 'Đơn liên kết',
    totalMembers: 'Tổng số thành viên',
    currentMembers: 'Thành viên hiện tại',
    todayDepositAmount: 'Tổng số tiền gửi hôm nay',
    todayWithdrawAmount: 'Tổng số tiền rút hôm nay',
    todayWithdrawDepositProfit: 'Lợi nhuận rút/gửi tiền hôm nay',
    manualMoney: 'Tiền thủ công',
    manualPoint: 'Điểm thủ công',
    totalGrossProfit: 'Tổng lợi nhuận gộp',
    casinoBetAmount: 'Số tiền cược sòng bạc',
    casinoProfit: 'Lợi nhuận sòng bạc',
    casinoRollingAmount: 'Số tiền lăn xóc sòng bạc',
    casinoGrossProfit: 'Tổng lợi nhuận sòng bạc',
    slotBetAmount: 'Số tiền cược máy đánh bạc',
    slotProfit: 'Lợi nhuận máy đánh bạc',
    slotRollingAmount: 'Số tiền lăn xóc máy đánh bạc',
    slotGrossProfit: 'Tổng lợi nhuận máy đánh bạc',
    sportBetAmount: 'Số tiền cược thể thao',
    sportProfit: 'Lợi nhuận thể thao',
    sportRollingAmount: 'Số tiền lăn xóc thể thao',
    sportGrossProfit: 'Tổng lợi nhuận thể thao',
    miniGameBetAmount: 'Số tiền cược mini game',
    miniGameProfit: 'Lợi nhuận mini game',
    miniGameRollingAmount: 'Số tiền lăn xóc mini game',
    miniGameGrossProfit: 'Tổng lợi nhuận mini game',
    memberShareRatio: 'Tỷ lệ hoàn trả thành viên',
    updateShareRatio: 'Cập nhật tỷ lệ hoàn trả',
    displayStartTime: 'Hiển thị thời gian bắt đầu',
    displayEndTime: 'Hiển thị thời gian kết thúc',
    batchFrozenMember: 'Đóng băng thành viên hàng loạt',
    showLogo: 'hiển thị biểu tượng',
    isSeamlessTransfer: 'Không cần chuyển nhượng',
  },
  message: {
    startenddatemore3months:
      'Ngày bắt đầu và kết thúc không được vượt quá 3 tháng',
    addRemarkSuccess: 'Thêm ghi chú thành công',
    addSuccess: 'Thêm thành công',
    adjustSuccess: 'Điều chỉnh thành công',
    affiliateApproved: 'Đối tác liên kết đã được phê duyệt',
    affiliateDisabled: 'Đối tác liên kết đã bị vô hiệu hóa',
    autopaySuccess: 'Tự động thanh toán thành công',
    cancelBbDachaSuccess: 'Hủy BB Dacha thành công',
    cancelSuccess: 'Hủy thành công',
    cancelDepositSuccess: 'Hủy khoản gửi thành công',
    cancelQuizSuccess: 'Hủy bài kiểm tra thành công',
    cancelRolloverRecordSuccess: 'Hủy thành công bản ghi Rollover',
    changeAffiliateSuccess: 'Thay đổi đối tác liên kết thành công',
    confirmUnbindAffiliateAccesss: 'Xác nhận gỡ ràng buộc đại lý cấp trên',
    changeSuccess: 'Thay đổi thành công',
    commissionPaySuccess: 'Thanh toán hoa hồng thành công',
    confirmToAdjust:
      'Bạn có chắc chắn muốn điều chỉnh không? Sau khi xác nhận, điều chỉnh không thể hoàn tác.',
    confirmCancelBbDacha:
      'Bạn có chắc chắn muốn hủy BB Dacha này? Hành động này không thể hoàn tác.',
    confirmCancelMatch:
      'Bạn có chắc chắn muốn hủy trận đấu này? Hành động này không thể hoàn tác.',
    confirmCancelQuiz:
      'Bạn có chắc chắn muốn hủy bài kiểm tra này? Hành động này không thể hoàn tác.',
    confirmCancelRebate:
      'Bạn có chắc chắn muốn hủy ghi nhận hoàn tiền này? Hành động này không thể hoàn tác.',
    confirmCopy: 'Bạn có muốn sao chép không?',
    confirmCopyTo: 'sao chép đến',
    confirmDelete:
      'Bạn có chắc chắn muốn xóa dữ liệu này? Hành động này không thể hoàn tác.',
    confirmRunAtOnce:
      'Bạn có muốn chạy hành động này ngay bây giờ không? (Hành động sẽ được thực hiện ngay cả khi bị vô hiệu hóa)',
    confirmDelivered:
      'Bạn có đã giao hàng thành công chưa? Hành động này không thể hoàn tác.',
    confirmEndMatch:
      'Bạn có chắc chắn muốn kết thúc trận đấu này? Hành động này không thể hoàn tác.',
    confirmRebate:
      'Bạn có chắc chắn muốn phân phối tất cả hoàn tiền không? Hành động này không thể hoàn tác.',
    confirmBatchRebate:
      'Vui lòng xác nhận phân phối hoàn tiền cho các mục đã chọn. Hành động này không thể hoàn tác.',
    confirmSingleRebate:
      'Vui lòng xác nhận phân phối hoàn tiền cho mục này. Hành động này không thể hoàn tác.',
    confirmRedeem:
      'Bạn có chắc chắn muốn đổi món quà này thành tiền mặt không? Hành động này không thể hoàn tác.',
    confirmRefer:
      'Bạn có chắc chắn muốn phân phối tất cả chương trình giới thiệu không? Hành động này không thể hoàn tác.',
    confirmReset:
      'Bạn có chắc chắn muốn đặt lại dữ liệu này không? Hành động này không thể hoàn tác.',
    confirmSettlement: 'Bạn có muốn tiến hành thanh toán không?',
    confirmStartMatch: 'Bạn có chắc chắn muốn bắt đầu trận đấu này không?',
    confirmToChecking:
      'Bạn có chắc chắn muốn chuyển thanh toán này thành kiểm tra không?',
    confirmToPay: 'Bạn có muốn tiến hành thanh toán không?',
    confirmToCheck: 'Bạn có muốn cập nhật không?',
    confirmUpdate: 'Bạn có muốn cập nhật không?',
    confirmUpdatePayment: 'Bạn có muốn cập nhật thanh toán không?',
    confirmUnbind: 'Bạn có muốn hủy kết nối thẻ ngân hàng không?',
    copyToClipboard: 'Đã sao chép vào Clipboard.',
    copySuccess: 'Sao chép thành công',
    deleteSuccess: 'Xóa thành công',
    runAtOnceSuccess: 'Chạy hành động thành công',
    dailyMinRegLimitCannotExceedMax:
      'Giới hạn đăng ký tối thiểu hàng ngày không thể vượt quá giới hạn đăng ký tối đa',
    dailyMinFirstDepositCannotExceedMax:
      'Số tiền gửi tối thiểu hàng ngày không thể vượt quá số tiền gửi tối đa',
    depositSuccess: 'Nạp tiền thành công',
    editAmountSuccess: 'Chỉnh sửa số tiền thành công',
    editRemarkSuccess: 'Chỉnh sửa ghi chú thành công',
    editSuccess: 'Chỉnh sửa thành công',
    editMemberDetail: 'Chỉnh sửa chi tiết thành viên',
    endMustAfterStart: 'Thời gian kết thúc phải sau thời gian bắt đầu.',
    enterMemberDetails: 'Nhập chi tiết thành viên',
    failedToUploadImage: 'Không thể tải lên hình ảnh',
    failedToUploadApp: 'Không thể tải lên ứng dụng',
    freezeMemberSuccess: 'Đóng băng thành viên thành công',
    unfreezeMemberSuccess: 'Tháo băng thành viên thành công',
    found: 'tìm thấy',
    gameMatchEnded: 'trận đấu kết thúc',
    giftDelivered: 'quà tặng đã giao',
    giftNoCashRedeemAmount:
      'không có số tiền đổi quà thành tiền mặt. Vui lòng thiết lập số tiền đổi quà thành tiền mặt trước.',
    giftOutForDelivery: 'quà tặng đang trong quá trình giao hàng',
    importSuccess: 'nhập thành công',
    inputDigits: 'nhập các chữ số',
    inputPassword: 'nhập mật khẩu',
    inputPasswordAgain: 'nhập lại mật khẩu',
    invalidFileType: 'kiểu tệp không hợp lệ',
    length6To12: 'độ dài từ 6 đến 12 ký tự',
    levelUpCreditMoreThan: 'tín dụng lên cấp phải',
    logoutPlayerSuccess: 'đăng xuất người chơi thành công',
    maxGreaterThanMin: 'giá trị tối đa phải lớn hơn giá trị tối thiểu',
    memberNotInSite: 'không tìm thấy thành viên trên trang web này',
    affiliateNotInSite: 'không tìm thấy đối tác liên kết trên trang web này',
    memberFreeGameLogin:
      'tên đăng nhập, nếu nhiều hơn một, hãy phân tách bằng dấu phẩy (tối đa 200 người)',
    memberFreeGameRound: 'vòng chơi game miễn phí',
    memberFreeGameIds:
      'có thể sử dụng ID game và nếu nhiều hơn một, hãy phân tách bằng dấu phẩy. Độ dài tối đa (bao gồm cả dấu phẩy) là 200',
    multipleQuerySeparatedBySpace:
      'điều kiện truy vấn nhiều được phân tách bằng dấu cách',
    multiwheelprizeform: 'chèn số 0 vào mục tiêu',
    noAvailablePrivilege: 'không có đặc quyền có sẵn hiện tại',
    passwordLength: 'mật khẩu phải có độ dài từ 6 đến 12 ký tự',
    paymentUpdate:
      'Bạn có muốn sao chép sang một phương thức hoặc một cấp độ khác không? Nếu không, hãy nhấn nút Cập nhật',
    paySuccess: 'Thanh toán thành công',
    pleaseEnterAffiliateNameOrAffiliateCode:
      'Vui lòng nhập tên đối tác hoặc mã đối tác',
    promoDistributionSuccess: 'Phân phối khuyến mãi thành công',
    redeemCashSuccess: 'Đổi tiền mặt thành công',
    reenterPassword: 'Nhập lại mật khẩu',
    rebateSuccess:
      'Phân phối hoàn tiền VIP đang được xử lý. Vui lòng kiểm tra lại sau để xem lịch sử.',
    registerSuccess: 'Đăng ký thành công',
    registerSuccessInd:
      'Đăng ký thành công. Vui lòng thiết lập cài đặt hiển thị tiền gửi của đối tác mới.',
    referSuccess: 'Phân phối khuyến mãi giới thiệu thành công',
    replySuccess: 'Trả lời thành công',
    removePreviousLevel: 'Xóa cấp độ trước',
    removeRemarkSuccess: 'Xóa ghi chú thành công',
    replyFeedback: 'Trả lời phản hồi',
    resetSuccess: 'Đặt lại thành công',
    resendSuccess: 'Gửi lại thành công',
    selectDateNotMoreThan3Month:
      'Phạm vi ngày không được vượt quá 3 tháng. Vui lòng chọn lại',
    selectPreviousLevel: 'Chọn cấp độ trước',
    selectSiteFirst: 'Vui lòng chọn trang web trước',
    selectSiteAndGameTypeFirst:
      'Vui lòng chọn trang web và loại trò chơi trước',
    selectPaymentType: 'Chọn loại thanh toán',
    selectMobilePayment: 'Chọn thanh toán di động',
    selectWebPayment: 'Chọn thanh toán trên web',
    settlementToChecking: 'Đang chờ kiểm tra',
    settlementToPay: 'Đã thanh toán',
    settled: 'Đã xử lý thành công',
    startMustBeforeEnd: 'Thời gian bắt đầu phải trước thời gian kết thúc',
    success: 'Thành công',
    summarySuccess:
      'Tóm tắt cho khoảng thời gian đã nhập sẽ được tạo. Vui lòng kiểm tra lại sau để xem lịch sử.',
    transferSuccess: 'Chuyển tiền thành công',
    twoPasswordNotMatch: 'Hai mật khẩu không khớp',
    unbindSuccess: 'Hủy kết nối thẻ ngân hàng thành công',
    unlockMemberSuccess: 'Mở khóa thành viên thành công',
    unlockUserSuccess: 'Mở khóa người dùng thành công',
    updateBelongTypeSuccess: 'Cập nhật loại phụ thuộc thành công',
    updateCommissionModelSuccess: 'Cập nhật mô hình hoa hồng thành công',
    updateCommissionRateSuccess: 'Cập nhật tỷ lệ hoa hồng thành công',
    updateFinancialLevelSuccess: 'Cập nhật cấp độ tài chính thành công',
    updatePasswordSuccess: 'Cập nhật mật khẩu thành công',
    updatePayment: 'Cập nhật thanh toán',
    updateProceed: 'Cập nhật nút tiến hành để chỉnh sửa thanh toán',
    updateRiskLevelSuccess: 'Cập nhật cấp độ rủi ro thành công',
    updateSuccess: 'Cập nhật thành công',
    updateWithdraw: 'Cập nhật rút tiền',
    updateTimeTypeSuccess: 'Cập nhật loại thời gian thành công',
    updateToApplySuccess: 'Cập nhật để áp dụng thành công',
    updateToBeforePaidSuccess: 'Cập nhật trước khi thanh toán thành công',
    updateToFailSuccess: 'Cập nhật để thất bại thành công',
    updateToPaySuccess: 'Cập nhật để thanh toán thành công',
    updateToSuspendSuccess: 'Cập nhật để đình chỉ thành công',
    updateToUnderReviewSuccess: 'Cập nhật để đang xem xét thành công',
    updateUserTypeSuccess: 'Cập nhật loại người dùng thành công',
    updateVIPLevelSuccess: 'Cập nhật cấp độ VIP thành công',
    useCommaToSeparateDomain: 'Sử dụng dấu phẩy để phân tách các miền',
    validateAdsTypeRequired: 'Loại quảng cáo là bắt buộc',
    validateActionRequired: 'Hành động là bắt buộc',
    validateActivePlayerRequired: 'Người chơi hoạt động là bắt buộc',
    validateAdjustAmountRequired: 'Số tiền điều chỉnh là bắt buộc',
    validateAdjustReasonRequired: 'Lý do điều chỉnh là bắt buộc',
    validateAdjustTypeRequired: 'Loại điều chỉnh là bắt buộc',
    validateAffiliateFbIdRequired: 'Facebook pixel ID is required',
    validateAffiliateFbTokenRequired: 'Facebook pixel token is required',
    validateAffiliateFbUrlRequired: 'Facebook pixel url is required',
    validateAffiliateCodeRequired: 'Mã đối tác là bắt buộc',
    validateAffiliateLevelRequired: 'Cấp độ đối tác là bắt buộc',
    validateAffiliateNameRequired: 'Tên đối tác là bắt buộc',
    validateAliasRequired: 'Bút danh là bắt buộc',
    validateAllBonusRequired: 'Tất cả các khoản thưởng là bắt buộc',
    validateAlphaNumericOnly: 'Chỉ nhập chữ và số',
    validateAmountRequired: 'Số tiền là bắt buộc',
    validateAnnouncementTypeRequired: 'Loại thông báo là bắt buộc',
    validateAnswerOneRequired: 'Câu trả lời cho câu hỏi 1 là bắt buộc',
    validateAnswerOneAtLeastTwoChoices:
      'Câu trả lời cho câu hỏi 1 cần ít nhất hai lựa chọn',
    validateAnswerTwoRequired: 'Câu trả lời cho câu hỏi 2 là bắt buộc',
    validateAnswerTwoAtLeastTwoChoices:
      'Câu trả lời cho câu hỏi 2 cần ít nhất hai lựa chọn',
    validateAnswerThreeRequired: 'Câu trả lời cho câu hỏi 3 là bắt buộc',
    validateAnswerThreeAtLeastTwoChoices:
      'Câu hỏi 3 cần có ít nhất hai lựa chọn',
    validateAppTypeRequired: 'Yêu cầu loại ứng dụng',
    validateAtLeastSixChar: 'Ít nhất 6 ký tự',
    validateAwardsRequired: 'Yêu cầu giải thưởng',
    validateAwayTeamRequired: 'Yêu cầu đội khách',
    validateAwayTeamIconRequired: 'Yêu cầu biểu tượng đội khách',
    validateBankRequired: 'Yêu cầu ngân hàng',
    validateBankCardNumberRequired: 'Yêu cầu số thẻ ngân hàng',
    validateBankCodeRequired: 'Yêu cầu mã ngân hàng',
    validateBankNameRequired: 'Yêu cầu tên ngân hàng',
    validateBankTypeRequired: 'Yêu cầu loại ngân hàng',
    validateBeanNameRequired: 'Yêu cầu tên đối tượng',
    validateBetAmountRequired: 'Yêu cầu số tiền cược',
    validateBonusRequired: 'Yêu cầu bonus',
    validateBonusAmountRequired: 'Yêu cầu số tiền bonus',
    validateBonusAmountRatioRequired: 'Yêu cầu tỷ lệ số tiền bonus',
    validateBonusTypeRequired: 'Yêu cầu loại bonus',
    validateCardAccountRequired: 'Yêu cầu tài khoản thẻ',
    validateCardAddressRequired: 'Yêu cầu địa chỉ thẻ',
    validateCardNumberRequired: 'Yêu cầu số thẻ',
    validateCategoryRequired: 'Yêu cầu danh mục',
    validateCauseRequired: 'Yêu cầu nguyên nhân',
    validateChoiceOneRequired: 'Yêu cầu đội chủ nhà và đội khách',
    validateChoiceTwoRequired: 'Yêu cầu loại trò chơi',
    validateChoiceThreeRequired: 'Yêu cầu loại trò chơi',
    validateChoiceRequired: 'Yêu cầu lựa chọn',
    validateClassNameRequired: 'Yêu cầu tên lớp',
    validateCodeRequired: 'Yêu cầu mã',
    validateCodeExist: 'Mã đã tồn tại. Vui lòng nhập mã khác. - Mã hiện tại: ',
    validateColorRequired: 'Yêu cầu màu sắc',
    validateCommissionRequired: 'Yêu cầu hoa hồng',
    validateCommissionFormat: 'Yêu cầu định dạng hoa hồng từ 0 đến 1',
    validateCommissionModelRequired: 'Yêu cầu mô hình hoa hồng',
    validateConfigGroupRequired: 'Yêu cầu nhóm cấu hình',
    validateConfirmPasswordRequired: 'Yêu cầu xác nhận mật khẩu',
    validateConfirmPasswordSize: 'Xác nhận mật khẩu từ 6 đến 12 ký tự',
    validateContentRequired: 'Yêu cầu nội dung',
    validateCopyFinancialLevel: 'Chọn ít nhất 1 cấp độ để sao chép',
    validateCountryImageRequired: 'Yêu cầu hình ảnh quốc gia',
    validateCronExpressionFormat: 'Định dạng biểu thức Cron không hợp lệ',
    validateCronExpressionRequired: 'Yêu cầu biểu thức Cron',
    validateCurrencyRequired: 'Yêu cầu tiền tệ',
    validateCurrencyCodeRequired: 'Yêu cầu mã tiền tệ',
    validateCurrencyNameRequired: 'Yêu cầu tên tiền tệ',
    validateCustomerSupportAddressFormat:
      'Địa chỉ hỗ trợ khách hàng không hợp lệ',
    validateDailyMinRequired: 'Yêu cầu số vòng quay tối thiểu hàng ngày',
    validateDailyMaxRequired: 'Yêu cầu số vòng quay tối đa hàng ngày',
    validateDateRequired: 'Yêu cầu ngày',
    validateDayWithdrawCountRequired: 'Yêu cầu số lần rút tiền hàng ngày',
    validateDefaultBonusRequired: 'Yêu cầu bonus mặc định',
    validateDepositExchangeRateRequired: 'Yêu cầu tỷ giá nạp tiền',
    validateDepositPerSpinRequired: 'Yêu cầu nạp tiền mỗi lần quay',
    validateDesktopImageRequired: 'Yêu cầu hình ảnh máy tính để bàn',
    validateDesktopBannerRequired: 'Yêu cầu banner máy tính để bàn',
    validateDeviceRequired: 'Yêu cầu thiết bị',
    validateDisplayEndTimeBeforeStartTime: 'Thời gian kết thúc hiển thị không được sớm hơn thời gian bắt đầu hiển thị',
    validateDomainRequired: 'Yêu cầu miền',
    validateEmailRequired: 'Yêu cầu email',
    validateEmailFormat: 'Nhập định dạng email hợp lệ',
    validateEndDateRequired: 'Yêu cầu ngày kết thúc',
    validateEndTimeRequired: 'Yêu cầu thời gian kết thúc',
    validateExchangeRateRequired: 'Yêu cầu tỷ giá',
    validateExpressCompanyRequired: 'Yêu cầu công ty chuyển phát',
    validateFailReasonRequired: 'Yêu cầu lý do thất bại',
    validateFeeRateRequired: 'Yêu cầu tỷ lệ phí',
    validateFreezeTypeRequired: 'Yêu cầu loại đóng băng',
    validateFinancialLevelRequired: 'Yêu cầu cấp độ tài chính',
    validateFileRequired: 'Yêu cầu tập tin ứng dụng',
    validateFrequencyRequired: 'Yêu cầu tần suất',
    validateGameCodeRequired: 'Yêu cầu mã trò chơi',
    validateGameNameRequired: 'Yêu cầu tên trò chơi',
    validateGameTypeRequired: 'Yêu cầu loại trò chơi',
    validateHomeTeamRequired: 'Yêu cầu đội chủ nhà',
    validateHomeTeamIconRequired: 'Yêu cầu biểu tượng đội chủ nhà',
    validateIconRequired: 'Yêu cầu biểu tượng',
    validateIdentifyCodeRequired: 'Yêu cầu mã nhận dạng',
    validateIpRequired: 'Yêu cầu IP',
    validateJobNameRequired: 'Yêu cầu tên công việc',
    validateLabelRequired: 'Yêu cầu nhãn',
    validateLevelRequired: 'Yêu cầu cấp độ',
    validateLevelNameRequired: 'Yêu cầu tên cấp độ',
    validateLevelUpCreditRequired: 'Yêu cầu tín dụng nâng cấp cấp độ',
    validateLimitNumberRequired: 'Yêu cầu số giới hạn',
    validateLoginNameRequired: 'Yêu cầu tên đăng nhập',
    validateLoginNameSize: 'Tên đăng nhập từ 6 đến 12 ký tự',
    validateMaintenanceTimeRequired: 'Yêu cầu thời gian bảo trì',
    validateMallCodeRequired: 'Yêu cầu mã cửa hàng',
    validateMallKeyRequired: 'Yêu cầu khóa cửa hàng',
    validateMallNameRequired: 'Yêu cầu tên cửa hàng',
    validateMatchTitleRequired: 'Yêu cầu tiêu đề trận đấu',
    validateMatchTimeRequired: 'Yêu cầu thời gian trận đấu',
    validateMaxDailyWithdrawRequired: 'Yêu cầu số tiền rút tối đa hàng ngày',
    validateMaxDailyWithdrawNumber:
      'Số tiền rút tối đa không thể nhỏ hơn hoặc bằng 0',
    validateMaxDailyWithdrawGreater:
      'Số tiền rút tối đa phải lớn hơn số tiền rút tối thiểu',
    validateMaxDailyWithdrawTimesRequired:
      'Yêu cầu số lần rút tối đa hàng ngày',
    validateMaxDailyWithdrawTimesNumber:
      'Số lần rút tối đa không thể nhỏ hơn hoặc bằng 0',
    validateMaxDepositRequired: 'Yêu cầu số tiền nạp tối đa',
    validateMaxDepositGreater:
      'Số tiền nạp tối đa phải lớn hơn số tiền nạp tối thiểu',
    validateMaxWithdrawAmountRequired: 'Yêu cầu số tiền rút tối đa',
    validateMaxWithdrawAmountNumber:
      'Số tiền rút tối đa không thể nhỏ hơn hoặc bằng 0',
    validateMaxWithdrawAmountGreater:
      'Số tiền rút tối đa phải lớn hơn số tiền rút tối thiểu',
    validateMemberTypeRequired: 'Yêu cầu loại thành viên',
    validateMemberIdRequired: 'Yêu cầu ID thành viên',
    validateMenuTitleRequired: 'Yêu cầu tiêu đề menu',
    validateMinBalanceRequired: 'Yêu cầu số dư tối thiểu',
    validateMinDepositRequired: 'Yêu cầu số tiền nạp tối thiểu',
    validateMinDepositLesser:
      'Số tiền nạp tối thiểu không thể lớn hơn số tiền nạp tối đa',
    validateMinWithdrawRequired: 'Yêu cầu số tiền rút tối thiểu',
    validateMinWithdrawNumber:
      'Số tiền rút tối thiểu không thể nhỏ hơn hoặc bằng 0',
    validateMinWithdrawLesser:
      'Số tiền rút tối thiểu không thể lớn hơn số tiền rút tối đa',
    validateMobileImageRequired: 'Yêu cầu hình ảnh di động',
    validateMobileBannerRequired: 'Yêu cầu banner di động',
    validateMobileMessageRequired: 'Yêu cầu tin nhắn di động',
    validateNameRequired: 'Yêu cầu tên',
    validateNeedRegisterRequired: 'Yêu cầu đăng ký',
    validateNetProfitRequired: 'Yêu cầu lợi nhuận ròng',
    validateNextActivationTimeRequired: 'Yêu cầu thời gian kích hoạt tiếp theo',
    validateNextGetBetEndTime: 'Yêu cầu thời gian kết thúc cược tiếp theo',
    validateNextGetBetPage: 'Yêu cầu trang cược tiếp theo',
    validateNextGetBetStartTime: 'Yêu cầu thời gian bắt đầu cược tiếp theo',
    validateNoNullValue: 'Giá trị không thể là null',
    validateNoRepeatAmount: 'Không thể có cùng một số tiền',
    validateNumberOnly: 'Chỉ nhập số',
    validateNumberFourDecimalOnly: 'Chỉ nhập số, từ 0 đến 4 chữ số thập phân',
    validateOneTimeSpinRequired: 'Yêu cầu quay một lần',
    validateOnlyThreeChar: 'Chỉ nhập 3 ký tự',
    validateOrderTrackingNoRequired: 'Yêu cầu mã theo dõi đơn hàng',
    validateOsRequired: 'Chọn ít nhất một hệ điều hành',
    validateParamRequired: 'Yêu cầu tham số',
    validatePasswordRequired: 'Yêu cầu mật khẩu',
    validatePasswordSize: 'Mật khẩu từ 6 đến 12 ký tự',
    validatePaymentFeeFormat: 'Tỷ lệ phí thanh toán từ 0 đến 1',
    validatePaymentNameRequired: 'Yêu cầu tên thanh toán',
    validatePaymentTypeRequired: 'Yêu cầu loại thanh toán',
    validatePayResultTypeRequired: 'Yêu cầu loại kết quả thanh toán',
    validatePayTypeRequired: 'Yêu cầu loại thanh toán',
    validatePayTypeCodeRequired: 'Yêu cầu mã loại thanh toán',
    validatePayTypeNameRequired: 'Yêu cầu tên loại thanh toán',
    validatePayTypeNameCodeDifferent:
      'Tên và mã loại thanh toán không thể giống nhau',
    validatePlatformRequired: 'Yêu cầu nền tảng',
    validatePlatformAccountRequired: 'Yêu cầu tài khoản nền tảng',
    validatePlatformCodeRequired: 'Yêu cầu mã nền tảng',
    validatePlatformFeeFormat: 'Định dạng phí nền tảng từ 0 đến 1',
    validatePlatformNameRequired: 'Yêu cầu tên nền tảng',
    validatePrivilegeRequired: 'Yêu cầu đặc quyền',
    validatePromoCodeRequired: 'Yêu cầu mã khuyến mãi',
    validatePromoTypeRequired: 'Yêu cầu loại khuyến mãi',
    validateQueryNumberRequired: 'Yêu cầu số truy vấn',
    validateQueryRestrictionRequired: 'Yêu cầu ràng buộc truy vấn',
    validateQuestionOneRequired: 'Yêu cầu câu hỏi 1',
    validateQuestionRequired: 'Yêu cầu câu hỏi',
    validateQuizTimeRequired: 'Yêu cầu thời gian trò chơi',
    validateQuizTitleRequired: 'Yêu cầu tiêu đề trò chơi',
    validateRateRequired: 'Yêu cầu tỷ lệ',
    validateRealNameRequired: 'Yêu cầu tên thật',
    validateReasonRequired: 'Yêu cầu lý do',
    validateReasonTypeRequired: 'Yêu cầu loại lý do',
    validateRebatePercentageRequired: 'Yêu cầu phần trăm hoàn trả',
    validateMaxRebateRequired: 'Yêu cầu hoàn trả tối đa',
    validateRecipientRequired: 'Yêu cầu người nhận',
    validateRecordTimeRequired: 'Yêu cầu thời gian ghi âm',
    validateRedeemPointsRequired: 'Yêu cầu điểm đổi',
    validateRedirectRequired: 'Yêu cầu chuyển hướng',
    validateReenterPasswordRequired: 'Yêu cầu nhập lại mật khẩu',
    validateRemarkRequired: 'Yêu cầu ghi chú',
    validateRequestUrlRequired: 'Yêu cầu URL yêu cầu',
    validateResultDateRequired: 'Yêu cầu ngày kết quả',
    validateResultNumber: 'Yêu cầu số kết quả 3 chữ số',
    validateRebateFormat: 'Định dạng hoàn trả từ 0 đến 1',
    validateRevenueShareFormat: 'Định dạng chia sẻ doanh thu từ 0 đến 1',
    validateRewardGroupRequired: 'Yêu cầu nhóm phần thưởng',
    validateRewardGroupNameRequired: 'Yêu cầu tên nhóm phần thưởng',
    validateRewardGroupDownlineQuotaRequired: 'Yêu cầu hạn ngạch dưới nhóm',
    validateRewardGroupDownlineQuotaFormat: 'Hạn ngạch dưới phải lớn hơn 0',
    validateRiskLevelRequired: 'Yêu cầu mức độ rủi ro',
    validateRoleRequired: 'Yêu cầu vai trò',
    validateRoleNameRequired: 'Yêu cầu tên vai trò',
    validateRolloverRequired: 'Yêu cầu lật',
    validateGameRolloverRequired: "Loại trò chơi và doanh thu phải chọn",
    validateGameRolloverSelectRequired: "Loại trò chơi và doanh thu phải chọn",
    validateRoundRequired: 'Yêu cầu vòng',
    validateRulesRequired: 'Yêu cầu luật lệ',
    validateSchemaNameRequired: 'Yêu cầu tên lược đồ',
    validateSequenceRequired: 'Yêu cầu chuỗi',
    validateSiteRequired: 'Yêu cầu trang web',
    validateSiteCodeRequired: 'Yêu cầu mã trang web',
    validateStageRequired: 'Yêu cầu giai đoạn',
    validateStartTimeRequired: 'Yêu cầu thời gian bắt đầu',
    validateStateRequired: 'Yêu cầu trạng thái',
    validateStatusRequired: 'Yêu cầu trạng thái',
    validateStepsRequired: 'Yêu cầu bước thiết lập',
    validateStepBonusRequired: 'Yêu cầu tiền thưởng bước',
    validateStopAfterFailureRequired: 'Yêu cầu dừng sau khi thất bại',
    validateSubjectRequired: 'Yêu cầu môn học',
    validateSuccess: 'Thành công xác thực',
    validateSupportTypeRequired: 'Yêu cầu loại hỗ trợ',
    validateSureWinBonusRequired: 'Yêu cầu tiền thưởng chắc chắn thắng',
    validateSureWinMinSpinRequired: 'Yêu cầu spin tối thiểu chắc chắn thắng',
    validateTeamGroupRequired: 'Yêu cầu nhóm đội',
    validateTeamIconRequired: 'Yêu cầu biểu tượng đội',
    validateTeamNameRequired: 'Yêu cầu tên đội',
    validateTeamNameEnRequired: 'Yêu cầu tên tiếng Anh của đội',
    validateTeamNameLocalRequired: 'Yêu cầu tên địa phương của đội',
    validateTeamOneRequired: 'Yêu cầu đội 1',
    validateTeamOneIconRequired: 'Yêu cầu biểu tượng đội 1',
    validateTeamTwoRequired: 'Yêu cầu đội 2',
    validateTeamTwoIconRequired: 'Yêu cầu biểu tượng đội 2',
    validateTelephoneRequired: 'Yêu cầu số điện thoại',
    validateThirdSerialNumberRequired: 'Yêu cầu số thứ ba',
    validateThreeDigitsNumbeRequired: 'Yêu cầu số ba chữ số',
    validateTitleRequired: 'Yêu cầu tiêu đề',
    validateTimeTypeRequired: 'Yêu cầu loại thời gian',
    validateTimeZoneRequired: 'Cần thiết phải có múi giờ',
    validateTransactionIdRequired: 'Yêu cầu ID giao dịch',
    validateTransactionStatusRequired: 'Yêu cầu trạng thái giao dịch',
    validateTriggerTypeRequired: 'Yêu cầu loại kích hoạt',
    validateTypeRequired: 'Yêu cầu loại',
    validateUrlFormat: 'Vui lòng nhập URL chính xác',
    validateUsernameRequired: 'Yêu cầu tên người dùng',
    validateUsernameSize: 'Tên người dùng từ 6-12 ký tự',
    validateUserTypeRequired: 'Yêu cầu loại người dùng',
    validateValidBetPerSpinRequired: 'Yêu cầu cược hợp lệ mỗi lượt quay',
    validateValueRequired: 'Yêu cầu giá trị',
    validateVIPRequired: 'Yêu cầu VIP',
    validateWalletTypeRequired: 'Yêu cầu loại ví tiền',
    validateWayRequired: 'Yêu cầu cách thức',
    validateWebMessageRequired: 'Yêu cầu tin nhắn web',
    validateWholeNumberOnly: 'Chỉ nhập số nguyên',
    validateWinnerRequired: 'Yêu cầu người chiến thắng',
    validateWithdrawExchangeRateRequired: 'Yêu cầu tỷ giá rút tiền',
    validateWithdrawPlatformNameRequired: 'Yêu cầu tên nền tảng rút tiền',
    validateTigerCardPeriodRequired: 'Yêu cầu thời hạn thẻ Tiger',
    validateTigerCardTimeRequired: 'Yêu cầu thời gian thẻ Tiger',
    validateTigerCardBonusTimeRequired: 'Yêu cầu thời gian thưởng thẻ Tiger',
    validateTigerSumAwardRequired: 'Yêu cầu tổng thưởng Tiger',
    validateVirtualCountRequired: 'Yêu cầu số lượng ảo',
    validateHostRequired: 'Yêu cầu máy chủ',
    validatePortRequired: 'Yêu cầu cổng',
    validateSignNameRequired: 'Yêu cầu tên chữ ký',
    validateSearchCondition: 'Vui lòng nhập ít nhất một điều kiện truy vấn',
    validateSecretIdRequired: 'Yêu cầu ID bí mật',
    validateSecretKeyRequired: 'Yêu cầu khóa bí mật',
    validateAppIdRequired: 'Yêu cầu ID ứng dụng',
    validateTemplateIdRequired: 'Yêu cầu ID mẫu',
    requestExportToExcelDone1: 'Yêu cầu xuất thành công. Nhấp vào ',
    requestExportToExcelDone2: ' để kiểm tra tiến độ và tải xuống.',
    validateImageRequired: 'Yêu cầu hình ảnh',
    validateImageNameRequired: 'Yêu cầu tên hình ảnh',
    validatePosterTypeRequired: 'Yêu cầu loại poster',
    validateConfigCodeRequired: 'Yêu cầu mã cấu hình',
    validateConfigValueRequired: 'Loại cấu hình là bắt buộc',
    validateConfigTypeRequired: 'Config Type is required',
    resultCalculateSuccess: 'Tính toán kết quả thành công',
    buildIsInQueue: 'Đang xếp hàng để xây dựng',
    roleMustOnlyOne: 'Chỉ chọn một vai trò yêu cầu',
    validateCompetitionTypeRequired: 'Yêu cầu loại cuộc thi',
    validateCompetitionNameRequired: 'Yêu cầu tên cuộc thi',
    validateCompetitionTimeRequired: 'Yêu cầu thời gian cuộc thi',
    validateKeyQuantityRequired: 'Yêu cầu số lượng chìa khóa',
    validateMonthRequired: 'Yêu cầu năm/tháng',
    validateTimeRequired: 'Yêu cầu thời gian',
    syncMemberDetailSuccess: 'Đồng bộ chi tiết thành viên thành công',
    timeExist: 'Thời gian đã tồn tại',
    wrongPopupTime: 'Thời gian popup đã chọn không hợp lệ',
    selectPopupTime: 'Vui lòng chọn thời gian popup',
    updateAffiliateLevelSuccess: 'Cập nhật cấp đại lý thành công',
    validationMinDayRegLimitRequired:
      'Giới hạn đăng ký hàng ngày tối thiểu là bắt buộc',
    validationMaxDayRegLimitRequired:
      'Giới hạn đăng ký hàng ngày tối đa là bắt buộc',
    validationMinDayDepositAmountRequired:
      'Số tiền gửi tối thiểu hàng ngày là bắt buộc',
    validationMaxDayDepositAmountRequired:
      'Số tiền gửi tối đa hàng ngày là bắt buộc',
    validateMinDayRegLimitRequired:
      'Giới hạn đăng ký hàng ngày tối thiểu là bắt buộc',
    validateMaxDayRegLimitRequired:
      'Giới hạn đăng ký hàng ngày tối đa là bắt buộc',
    validateMinDayDepositAmountRequired:
      'Số tiền gửi tối thiểu hàng ngày là bắt buộc',
    validateMaxDayDepositAmountRequired:
      'Số tiền gửi tối đa hàng ngày là bắt buộc',
    validateDayRegProbabilityRequired: 'Xác suất đăng ký hàng ngày là bắt buộc',
    validateDayDepositAmountProbabilityRequired:
      'Xác suất gửi tiền lần đầu hàng ngày là bắt buộc',
    validateMediaDescRequired: 'Yêu cầu mô tả phương tiện',
    validateMediaUrlRequired: 'Yêu cầu liên kết video',
    validateDisplayUrlRequired: 'Yêu cầu liên kết hiển thị video',
    validateModeRequired: 'Chế độ cần thiết',
    validateAutoplayRequired: 'Yêu cầu tự động phát',
    validatePlanOddsRequired: 'Yêu cầu lãi suất đầu tư',
    validatePlanDaysRequired: 'Cần có thời gian đầu tư',
    validatePlanLimitMinRequired: 'Yêu cầu giới hạn đầu tư tối thiểu',
    validatePlanLimitMaxRequired: 'Yêu cầu giới hạn đầu tư tối đa',
    validateIsSeamlessTransferRequired: 'Nó là cần thiết để không chuyển nhượng',
  },
  menu: {
    undefined: '',
    Dashboard: 'Bảng điều khiển',
    Member: 'Thành viên',
    'Member List': 'Danh sách thành viên',
    'Member List ID': 'Danh sách thành viên',
    'Member Details': 'Chi tiết thành viên',
    'Member Edit Log': 'Nhật ký chỉnh sửa thành viên',
    'Member Feedback': 'Phản hồi thành viên',
    'Member Freeze Record': 'Lịch sử đóng băng thành viên',
    'Member Amount Adjust': 'Điều chỉnh số tiền thành viên',
    'Member Privilege Blacklist': 'Danh sách đen đặc quyền thành viên',
    'Member Bank Card History': 'Lịch sử thẻ ngân hàng của thành viên',
    'Member Sms': 'Lịch sử SMS của thành viên',
    'Import Bet Records': 'Nhập khẩu hồ sơ đặt cược',
    'Member VIP': 'Thành viên VIP',
    'Member Bet Record': 'Lịch sử đặt cược thành viên',
    'Member Transfer Record': 'Lịch sử chuyển khoản thành viên',
    'PG Pending': 'Hồ sơ PG đang chờ',
    'Member Privilege': 'Thành viên 특권',
    'Member Rollover Record': 'Lịch sử 롤오버 thành viên',
    'Member Distribute Privilege': 'Phân phối đặc quyền thành viên',
    'Member Profit Ranking': 'Thứ hạng lợi nhuận thành viên',
    'Member Affiliate Change Log': 'Member Affiliate Change Log',
    'Member Group Send SMS': 'Member Group Send SMS',
    Affiliate: 'Đối tác liên kết',
    'Affiliate Amount Adjust': 'Điều chỉnh số tiền Đối tác liên kết',
    'Affiliate Summary': 'Tóm tắt Đối tác liên kết',
    'Affiliate Monthly Settlement': 'Thanh toán hàng tháng Đối tác liên kết',
    'Affiliate Monthly Settlement Detail':
      'Chi tiết thanh toán hàng tháng Đối tác liên kết',
    'Affiliate Settlement Checking': 'Kiểm tra thanh toán Đối tác liên kết',
    'Affiliate Settlement Payment': 'Thanh toán Đối tác liên kết',
    'Affiliate Domain Management': 'Quản lý tên miền Đối tác liên kết',
    'Affiliate List': 'Danh sách Đối tác liên kết',
    'Affiliate Details': 'Chi tiết Đối tác liên kết',
    'Affiliate Financial Management': 'Quản lý tài chính Đối tác liên kết',
    'Affiliate Facebook Pixel Management':
      'Affiliate Facebook Pixel Management',
    'Affiliate Deposit Display': 'Hiển thị Đối tác liên kết nạp tiền',
    'Site Affiliate Commission': 'Hoa hồng Đối tác trang web',
    'Aff Announcement Management': 'Quản lý thông báo Đối tác',
    'Affiliate Announcement': 'Thông báo Đối tác',
    'Affiliate Announcement Type': 'Loại thông báo Đối tác',
    'App Version': 'Phiên bản ứng dụng',
    'Auto Withdraw Setting': 'Cài đặt rút tiền tự động',
    'Deposit Management': 'Quản lý nạp tiền',
    'Online Deposit': 'Nạp tiền trực tuyến',
    'Deposit Record': 'Lịch sử nạp tiền',
    'Withdrawal Auto Process': 'Xử lý rút tiền tự động',
    'Withdrawal Management': 'Quản lý rút tiền',
    'Withdrawal Process': 'Xử lý rút tiền',
    'Withdrawal Affiliate Process': 'Xử lý rút tiền Đối tác liên kết',
    'Withdrawal Record': 'Lịch sử rút tiền',
    'Withdrawal Affiliate Record': 'Lịch sử rút tiền Đối tác liên kết',
    'Withdrawal Platform Balance': 'Số dư nền tảng rút tiền',
    'Withdraw Review': 'Xem xét rút tiền',
    'Withdrawal Bank Blacklist': 'Danh sách đen ngân hàng rút tiền',
    'Withdraw Tips Setting': 'Cài đặt lời khuyên rút tiền',
    'Reward Group': 'Nhóm phần thưởng',
    Applying: 'Đang áp dụng',
    'Affiliate Applying': 'Đang áp dụng Đối tác liên kết',
    'Affiliate Under review': 'Đang xem xét Đối tác liên kết',
    BW1: 'BW1',
    BW2: 'BW2',
    BW3: 'BW3',
    BW4: 'BW4',
    BW5: 'BW5',
    'To be affiliate paid': 'Sắp thanh toán Đối tác liên kết',
    'Affiliate Payment on going': 'Đang thanh toán Đối tác liên kết',
    'Crypto Affiliate Payment on going':
      'Đang thanh toán Đối tác liên kết tiền điện tử',
    'Ewallt Affiliate Payment on going':
      'Đang thanh toán Đối tác liên kết ví điện tử',
    'Alipay Affiliate Payment on going':
      'Đang thanh toán Đối tác liên kết Alipay',
    'Affiliate Automatic Payment': 'Thanh toán tự động Đối tác liên kết',
    'Affiliate Suspend': 'Đình chỉ Đối tác liên kết',
    'Under review': 'Đang xem xét',
    'To be paid': 'Sắp thanh toán',
    'Payment on going': 'Đang thanh toán',
    'Crypto Payment on going': 'Đang thanh toán tiền điện tử',
    'Ewallt Payment on going': 'Đang thanh toán ví điện tử',
    'Alipay Payment on going': 'Đang thanh toán Alipay',
    'Automatic Payment': 'Thanh toán tự động',
    'Waiting Auto Pay': 'Đang chờ thanh toán tự động',
    'AutoWithdraw Under review': 'Đang xem xét rút tiền tự động',
    'AutoWithdraw Withdrawal Record': 'Lịch sử rút tiền tự động',
    Suspend: 'Đình chỉ',
    Promotion: 'Khuyến mãi',
    'Privilege Info': 'Thông tin đặc quyền',
    'Home Banner': 'Banner trang chủ',
    'Promotion pages': 'Trang khuyến mãi',
    'Edit Promo': 'Chỉnh sửa khuyến mãi',
    'Multi Wheel': 'Bánh xe đa năng',
    'Multi Wheel List': 'Danh sách thành viên bánh xe đa năng',
    'Multi Wheel Setting': 'Cài đặt bánh xe đa năng',
    'Multi Wheel Additional List': 'Danh sách bổ sung bánh xe đa năng',
    'Team Votes': 'Bình chọn nhóm',
    'Team Votes Records': 'Hồ sơ bình chọn nhóm',
    'Team Votes Settings': 'Cài đặt bình chọn nhóm',
    'Tiger Card': 'Thẻ hổ',
    'Telephone Numbers': 'Số điện thoại',
    'Ads Popout': 'Quảng cáo bật lên',
    'Add Ads Popout': 'Thêm quảng cáo bật lên',
    'Edit Ads Popout': 'Chỉnh sửa quảng cáo bật lên',
    'Red Packet Rain': 'Mưa phong bao đỏ',
    'Red Packet Rain List': 'Danh sách mưa phong bao đỏ',
    'Member Free Game': 'Trò chơi miễn phí cho thành viên',
    'Promo Code Redeem Config': 'Promo Code Redeem Config',
    Lottery: 'Xổ số',
    'Lottery Record List': 'Danh sách lịch sử xổ số',
    'Lottery Result List': 'Danh sách kết quả xổ số',
    'Vietnam Lottery Record List': 'Danh sách lịch sử xổ số Việt Nam',
    'Vietnam Lottery Result List': 'Danh sách kết quả xổ số Việt Nam',
    'VIP Rebate Record': 'Lịch sử hoàn tiền VIP',
    'Bet Rebate Record': 'Lịch sử hoàn tiền cược',
    'Refer Friend': 'Giới thiệu bạn bè',
    'Refer Friend Rebate Record': 'Lịch sử hoàn tiền giới thiệu bạn bè',
    Gift: 'Quản lý quà tặng',
    'Gift Info': 'Thông tin quà tặng',
    'Gift Record': 'Lịch sử quà tặng',
    'Promo Application': 'Đăng ký khuyến mãi',
    'Game Steps': 'Các bước trò chơi',
    'BB Dacha': 'BB Dacha',
    'VIP Wheel': 'Bánh xe VIP',
    'Bet Wheel': 'Bánh xe cược',
    'Piggy Bank': 'Hòm tiết kiệm con heo',
    'Site Management': 'Quản lý trang web',
    Message: 'Thông điệp',
    Announcement: 'Thông báo',
    'Announcement Type': 'Loại thông báo',
    'VIP Rebate': 'Hoàn trả VIP',
    VIP: 'VIP',
    Game: 'Trò chơi',
    'Game Tag': 'Thẻ trò chơi',
    'TFG Member Game Map': 'Ràng buộc mã trò chơi TFG',
    'Financial Level': 'Mức tài chính',
    'Risk Level': 'Mức độ rủi ro',
    'Ip Label': 'Nhãn IP',
    'Site Platform Management': 'Quản lý nền tảng trang web',
    'Game Match': 'Trò chơi phù hợp',
    'Game Quiz': 'Trò chơi câu hỏi',
    'Member Rebate': 'Hoàn trả thành viên',
    Questionnaire: 'Bảng câu hỏi',
    UEFA: 'UEFA',
    'UEFA Team': 'Đội UEFA',
    'UEFA Match': 'Trận đấu UEFA',
    'Refer Friend Manager': 'Quản lý giới thiệu bạn bè',
    Permission: 'Quyền hạn',
    Menu: 'Menu',
    Roles: 'Vai trò',
    User: 'Người dùng',
    Report: 'Báo cáo',
    'Privilege Record': 'Hồ sơ đặc quyền',
    'Summary Report': 'Báo cáo Tóm tắt',
    'Privilege Report': 'Báo cáo Đặc quyền',
    'Deposit Report': 'Báo cáo Tiền gửi',
    'Withdraw Report': 'Báo cáo Rút tiền',
    'Finance Report': 'Báo cáo Tài chính',
    'Affiliate Report': 'Báo cáo Đối tác liên kết',
    'Affiliate Deposit Members': 'Thành viên gửi tiền của Đối tác liên kết',
    'Platform Game Report': 'Báo cáo Trò chơi trên Nền tảng',
    'Site Member Report': 'Báo cáo Thành viên trang web',
    'Deposit Member Detail': 'Chi tiết Thành viên gửi tiền',
    System: 'Hệ thống',
    'Operator Log': 'Nhật ký Người điều hành',
    'Member Platform': 'Nền tảng Thành viên',
    Site: 'Trang web',
    Config: 'Cấu hình',
    Currency: 'Tiền tệ',
    Platform: 'Nền tảng',
    'Site Platform': 'Nền tảng Trang web',
    Schedule: 'Lịch trình',
    'Platform Account': 'Tài khoản Nền tảng',
    'Data Dictionary': 'Từ điển Dữ liệu',
    'Manual Summary': 'Tóm tắt Thủ công',
    'Affiliate Manual Summary': 'Tóm tắt Thủ công Đối tác liên kết',
    'Payment Management': 'Quản lý Thanh toán',
    'Payment Display List': 'Danh sách Hiển thị Thanh toán',
    'Bank Info': 'Thông tin Ngân hàng',
    'Bank Card': 'Thẻ Ngân hàng',
    'Payment Type': 'Loại Thanh toán',
    Payment: 'Thanh toán',
    'Add Payment': 'Thêm Thanh toán',
    'Edit Payment': 'Chỉnh sửa Thanh toán',
    CurrencyRate: 'Tỷ giá Tiền tệ',
    'Payment Display': 'Hiển thị Thanh toán',
    'Withdraw Platform': 'Nền tảng Rút tiền',
    'Withdraw Setting': 'Cài đặt Rút tiền',
    'Site Withdraw Platform': 'Nền tảng Rút tiền Trang web',
    'Deposit Setting': 'Cài đặt Tiền gửi',
    'Withdraw Display': 'Hiển thị Rút tiền',
    'Payment Bank Management': 'Quản lý Ngân hàng Thanh toán',
    'Withdraw Platform Bank Management': 'Quản lý Ngân hàng Nền tảng Rút tiền',
    'Summary Register Report': 'Báo cáo Đăng ký Tóm tắt',
    'Summary Fdp Report': 'Báo cáo Fdp Tóm tắt',
    'Summary Deposit Report': 'Báo cáo Tiền gửi Tóm tắt',
    'Summary Withdraw Report': 'Báo cáo Rút tiền Tóm tắt',
    'Summary Active Report': 'Báo cáo Hoạt động Tóm tắt',
    'Summary Member Report': 'Báo cáo Thành viên Tóm tắt',
    'Finance Feedback': 'Phản hồi Tài chính',
    'Email Setting': 'Cài đặt Email',
    'SMS Setting': 'Cài đặt SMS',
    DownloadManager: 'Quản lý Tải xuống',
    'Error Queue': 'Hàng đợi Lỗi',
    GoogleAuth: 'Xác thực Google',
    'Image Setting': 'Cài đặt Hình ảnh',
    'Privi Christmas': 'Giáng sinh Ưu đãi',
    'Affiliate APK Build': 'Xây dựng APK Đối tác liên kết',
    'Channel Summary': 'Tóm tắt Kênh',
    'Adjustment Reason': 'Lý do Điều chỉnh',
    'User Login Log': 'Nhật ký Đăng nhập Người dùng',
    'Online Stats': 'Thống kê Trực tuyến',
    'Promotion Activity': 'Hoạt động Khuyến mãi',
    'Platform Competition': 'Cạnh tranh Nền tảng',
    'Platform Competition Code': 'Mã Cạnh tranh Nền tảng',
    'Event Treasure Key': 'Khóa Bảo vật Sự kiện',
    'Event Check In Record': 'Nhật ký Đăng ký Sự kiện',
    'User Action Log': 'Nhật ký Hành động Người dùng',
    'Competition Management': 'Quản lý Cạnh tranh',
    'Competition List': 'Danh sách Cạnh tranh',
    'Competition Code': 'Mã Cạnh tranh',
    'Affiliate Recalculate': 'Tính toán lại Đối tác liên kết',
    Utils: 'Tiện ích',
    'Ip Location': 'Vị trí IP',
    'Member Summon Reward Record': 'Nhật ký Phần thưởng Triệu hồi Thành viên',
    'Redirect Notification': 'Thông báo Điều hướng',
    'Member Summon Event': 'Sự kiện Triệu hồi Thành viên',
    'Member Summon List': 'Danh sách Triệu hồi Thành viên',
    'Affiliate Bet Rebate Record': 'Nhật ký Hoàn trả Cược Đối tác liên kết',
    'KRW Stats Report': 'Báo cáo Thống kê KRW của Trang web',
    'MQ Log': 'Nhật ký MQ',
    'System Config': 'Cấu hình hệ thống',
    'Monitor And Notification': 'Giám sát và Thông báo',
    'Promotion Coupon': 'phiếu mua hàng',
    'Member Invite Limit': 'Giới hạn mời thành viên',
    'Affiliate Change Log': 'Nhật ký thay đổi phụ huynh liên kết',
    'Media Display Settings': 'Cài đặt hiển thị phương tiện',
    'Vip Report': 'Báo cáo VIP',
    'System Value Rules': 'Quy tắc giá trị hệ thống',
    'Sites Config': 'Cấu hình trang web',
    'Mooncake Fest Spin Record': '中秋节转盘 2024',
    'Site Platform Blacklist': 'Danh sách đen nền tảng trang web',
  },
  google: {
    google_auth_menu: 'Google Xác thực',
    download_install: 'Tải xuống ứng dụng',
    add_key: 'Thêm khóa bảo mật',
    bind_verify: 'Liên kết Xác thực Google',
    popup_title: 'Google Xác thực',
    popup_msg:
      'Google Xác thực là công cụ mật khẩu động tự động làm mới mỗi 30 giây. Khi thực hiện các hoạt động nhạy cảm như chuyển khoản tiền, rút tiền hoặc điều chỉnh cài đặt bảo mật trên backend, bạn cần nhập mã xác minh 6 chữ số này để xác thực.',
    popup_hint: 'Xác thực này phải được sử dụng cùng tài khoản proxy.',
    i_know: 'Tôi đã hiểu',
    download_way: 'Cách tải xuống:',
    download_through_way: '1. Tải xuống qua liên kết:',
    ios_download: 'Tải xuống iOS',
    android_download: 'Tải xuống Android',
    ios_scan_download: 'Quét tải xuống iOS',
    android_scan_download: 'Quét tải xuống Android',
    if_cannot_download_you_can:
      '2. Nếu không thể tải xuống, bạn có thể tìm kiếm trên Apple App Store.',
    or_search_in_google: 'hoặc trên Google Play Store.',
    download_and_install: ' để tải xuống và cài đặt.',
    next_step: 'Bước tiếp theo',
    keyin_pass: 'Nhập mật khẩu & Tiếp theo',
    keyin_your_password: 'Nhập mật khẩu đăng nhập',
    tips_installed:
      'Mẹo: Sau khi tải xuống và cài đặt xong, nhấn "Tiếp theo" để tiếp tục.',
    qr_code: 'Mã QR',
    secret_key: 'Khóa bí mật',
    add_step: 'Bước thêm',
    add_step_desc:
      'Mở Google Xác thực, nhấn vào "+" ở góc phải dưới cùng, chọn "Nhập khóa bằng tay" và nhập khóa bí mật được đề cập trước và liên kết với tài khoản bạn muốn. (Bạn có thể tự động thêm bằng cách quét mã QR.)',
    check_example: 'Xem ví dụ',
    back: 'Quay lại',
    small_tips: 'Mẹo nhỏ:',
    small_tips_info_1:
      '1. Nếu bạn mất điện thoại hoặc xóa ứng dụng xác thực, bạn có thể khôi phục xác thực bằng cách sử dụng khóa bảo mật. Hãy lưu trữ khóa một cách an toàn.',
    small_tips_info_2:
      '2. Để bảo vệ an ninh tài khoản, không hiển thị tài khoản proxy và địa chỉ backend proxy trong quá trình liên kết.',
    keyin_6_digit_google: 'Nhập mã xác thực Google 6 chữ số của bạn.',
    auth_code: 'Mã Xác thực Google',
    bind_now: 'Liên kết ngay bây giờ',
    please_enter_password: 'Vui lòng nhập mật khẩu.',
    added_success: 'Mã xác thực Google đã được thêm thành công.',
    if_not_google_auth_then:
      'Nếu bạn chưa liên kết Google Xác thực, bạn có thể bỏ qua bước này.',
    google_auth_code: 'Vui lòng nhập mã xác thực Google 6 chữ số.',
    register_count: 'Số người đăng ký hôm nay',
    register_limit: 'Số lượng có thể đăng ký hôm nay',
  },
  posterType: {
    overall: 'Tổng quan',
    app: 'Ứng dụng',
    sponsor: 'Nhà tài trợ',
    gift: 'Quà tặng',
    competition: 'Cuộc thi',
    event: 'Sự kiện',
    crypto: 'Tiền điện tử',
    affiliate: 'Lợi ích liên kết',
  },
  siteAppVersion: {
    version: 'Phiên bản',
    os: 'Hệ điều hành',
    appType: 'Loại ứng dụng',
    apkType: 'Loại khóa ứng dụng',
    fileUploadedSuccessfully: 'Tải lên tệp thành công',
    IOS: 'iOS',
    ANDROID: 'Android',
    ALL_SITE: 'Tất cả các trang web',
    SPORT: 'Ứng dụng Thể thao',
    ESPORT: 'Ứng dụng Esports',
    NORMAL: 'Bình thường',
    NEW_KEY: 'Khóa mới',
    appUpload: 'Tải lên ứng dụng',
    editAppUpload: 'Chỉnh sửa tải lên ứng dụng',
  },
  affiliateApk: {
    currentVersion: 'Phiên bản hiện tại',
    buildStatus: 'Trạng thái build',
    latestVersion: 'Phiên bản mới nhất',
    buildApk: 'Xây dựng APK',
    editParam: 'Chỉnh sửa tham số',
    status: {
      IN_QUEUE: 'Đang chờ',
      IN_PROGRESS: 'Đang tiến hành',
      SUCCESS: 'Thành công',
    },
  },
  priviEvent: {
    status: 'Trạng thái ghi nhận',
    cancel: 'Đã hủy',
    active: 'Đang hoạt động',
    settled: 'Đã giải quyết',
  },
  realtimeStatistics: {
    APP: 'Ứng dụng',
    H5: 'H5',
    Mobile: 'Di động',
    PC: 'PC',
    APPLY_WITHDRAW: 'Đăng ký rút tiền',
    APPLY_WITHDRAW_RISK: 'Áp dụng rủi ro rút tiền'
  },
  loginDevice: {
    WEB: 'Web',
    H5: 'H5',
    ANDROID: 'Android',
    IOS: 'iOS',
  },
  result: {
    win: 'Thắng',
    draw: 'Hòa',
    loss: 'Thua',
  },
  freeze: {
    NORMAL: 'Bình thường',
    TEMPORARY: 'Tạm thời',
    PERMANENT: 'Vĩnh viễn',
    UNFREEZE: 'Mở khóa',
  },
  reviewStatus: {
    PENDING: 'Đang chờ',
    APPROVED: 'Đã duyệt',
    REJECTED: 'Đã từ chối',
  },
  week: {
    monday: 'Thứ Hai',
    tuesday: 'Thứ Ba',
    wednesday: 'Thứ Tư',
    thursday: 'Thứ Năm',
    friday: 'Thứ Sáu',
    saturday: 'Thứ Bảy',
    sunday: 'Chủ Nhật',
  },
  domainType: {
    LANDING: 'Trang đích',
    DOWNLOAD: 'Trang tải xuống',
    AFFILIATE: 'Liên kết',
    HOME: 'Trang chủ',
  },
  monitorTitle: {
    MEMBER_STATISTICS: 'Người dùng trực tuyến',
    DEPOSIT_FLUCTUATION: 'Biến động tiền gửi',
    WITHDRAW_FLUCTUATION: 'Biến động rút tiền',
    BONUS_FLUCTUATION: 'Biến động tiền thưởng',
    MQ_LAST_CONSUME_TIME_CHECK: 'Chủ đề MQ không hoạt động',
    JOB_IDLE_CHECK_GAME_BET_RECORD_FETCH_TASK: 'Nhiệm vụ nhàn rỗi(Truy xuất dữ liệu cược)',
  },
  error: {
    400: 'Yêu cầu không hợp lệ',
    401: 'Yêu cầu chưa được xác thực',
    403: 'Từ chối truy cập',
    404: 'Không tìm thấy tài nguyên',
    405: 'Phương thức không được cho phép',
    500: 'Lỗi hệ thống',
    501: 'ID yêu cầu trùng lặp',
    502: 'Không thể kết nối đến máy chủ',
    504: 'Yêu cầu quá thường xuyên',
    601: 'Lỗi xác nhận mã thông báo',
    602: 'Mã thông báo đã hết hạn',
    603: 'Mã thông báo bị thiếu',
    604: 'Tài khoản đã đăng nhập trước đó',
    605: 'Tài khoản đã đăng xuất trước đó',
    606: 'Vai trò đã được gán cho người dùng. Vui lòng hủy bỏ vai trò này trước khi thử lại',
    607: 'Không thể xóa trang web khi lớp người dùng không phải là quản trị viên của trang web',
    700: 'Mật khẩu cũ và mới không thể giống nhau',
    701: 'Tham số đã tồn tại',
    707: 'Số dư không đủ',
    800: 'Lỗi xác nhận CAPTCHA',
    801: 'Mã CAPTCHA đã hết hạn',
    900: 'Không thể tìm thấy chi tiết thành viên',
    901: 'Không thể tìm thấy bản ghi liên kết',
    922: 'Tài khoản của bạn chưa được liên kết với vcall',
    1000: 'Đăng nhập thất bại, tên người dùng hoặc mật khẩu không đúng',
    1001: 'Đăng nhập thất bại, tài khoản đã bị vô hiệu hóa',
    1005: 'Đăng nhập thất bại, tài khoản đã bị khóa. Vui lòng thử lại vào ngày mai',
    1006: 'Đăng nhập thất bại, tài khoản không tồn tại',
    1007: 'Đơn đặt hàng rút tiền đã được gửi đi. Vui lòng thử lại sau 20 phút',
    1008: 'Google Authenticator đã được gắn kết trước đó. Vui lòng nhập mã xác nhận Google',
    1009: 'Mã xác nhận Google đã nhập không chính xác',
    1100: 'Quản trị viên bị hạn chế đăng nhập',
    1101: 'Tài khoản đăng nhập không thuộc sở hữu của trang web này',
    9000: 'Không thể tạo công việc đã lên lịch',
    9001: 'Không thể cập nhật công việc đã lên lịch',
    9002: 'Không thể xóa công việc đã lên lịch',
    10009: 'Vui lòng nhập ít nhất một điều kiện truy vấn',
    10010: 'Loại thông báo hệ thống đã tồn tại',
    10011: 'Tiêu đề thông báo hệ thống đã tồn tại',
    10012: 'Loại thông báo hệ thống không tồn tại',
    10013: 'Loại thông báo đang được sử dụng trong thông báo hiện có. Vui lòng xóa thông báo hiện có trước',
    10020: 'Tiêu đề của biểu ngữ đã tồn tại',
    10021: 'Không thể tìm thấy ID biểu ngữ',
    10030: 'Tiêu đề chương trình khuyến mãi đã tồn tại',
    10031: 'Không thể tìm thấy ID chương trình khuyến mãi',
    10032: 'Loại tệp hình ảnh không hợp lệ',
    10033: 'Không thể tải lên biểu ngữ hình ảnh',
    10034: 'Không thể tải lên hình ảnh chương trình khuyến mãi',
    1004: 'Google Authenticator đã được thêm trước đó. Vui lòng nhập mã xác nhận Google.',
    10040: 'Tên mức độ nguy hiểm hệ thống đã tồn tại',
    10041: 'Màu sắc mức độ nguy hiểm hệ thống đã tồn tại',
    10050: 'Nhãn IP hệ thống đã tồn tại',
    10051: 'Nhãn IP hệ thống không tồn tại',
    10110: 'Lỗi giao dịch chờ xử lý trên nền tảng',
    10111: 'Lỗi giao dịch cập nhật chờ xử lý trên nền tảng',
    11000: 'Đối tác không tồn tại',
    11006: 'Chi phí liên kết đối tác trang web đã tồn tại',
    11007: 'Chi phí liên kết đối tác trang web không tồn tại',
    11008: 'Bản ghi liên kết đối tác không tồn tại',
    11009: 'Chi phí liên kết phải lớn hơn chi phí liên kết con:',
    11010: 'Đối tác giới thiệu không thể có đối tác con',
    11011: 'Đại lý xuất sắc chưa thiết lập tỷ lệ hoàn tiền cho trò chơi',
    11012: 'Đại lý xuất sắc chưa thiết lập tỷ lệ hoàn tiền cho trò chơi',
    11013: 'Tỷ lệ hoàn tiền của đại lý con không thể vượt quá tỷ lệ hoàn tiền của đại lý cha',
    11014: 'Tỷ lệ hoàn tiền tổng của đại lý con không thể vượt quá tỷ lệ hoàn tiền của đại lý cha',
    11015: 'Đại lý này chưa thiết lập tỷ lệ hoàn tiền',
    11016: 'Tỷ lệ hoàn tiền của người chơi không thể vượt quá tỷ lệ hoàn tiền của đại lý',
    12000: 'Tên thông tin quyền hệ thống đã tồn tại',
    12001: 'Mã thông tin quyền hệ thống đã tồn tại',
    12002: 'Vui lòng chèn nhóm quyền',
    12009: "Định dạng dòng không đúng, vui lòng kiểm tra lại.",
    12010: 'Tên tiền tệ đã tồn tại',
    12011: 'Tỷ giá tiền tệ đã được cài đặt cho trang web này',
    12020: "Trạng thái bản ghi hiện tại không phải là 'Đang gửi'",
    12030: 'Tên trang web đã tồn tại',
    12031: 'Mã trang web đã tồn tại',
    12032: 'Trang web này là trang web cha',
    12033: 'Không thể thay đổi trang web cha',
    12034: 'Tên thanh toán đã tồn tại',
    12035: 'Mã thanh toán đã tồn tại',
    12036: 'Thông tin quyền hệ thống không tồn tại',
    12037: 'ID thanh toán hệ thống không tồn tại',
    12038: 'Số tiền vượt quá số tiền thưởng tối đa',
    12039: 'Thành viên đã bị đưa vào danh sách đen quyền rồi',
    12040: 'Tên thanh toán hệ thống không tồn tại',
    12041: 'Tên cửa hàng đã tồn tại',
    12042: 'Mã cửa hàng đã tồn tại',
    12050: 'Ngân hàng hệ thống không tồn tại',
    12051: 'Tên ngân hàng hệ thống đã tồn tại',
    12052: 'Mã ngân hàng hệ thống đã tồn tại',
    12053: 'Mã nhận dạng thẻ ngân hàng hệ thống đã tồn tại',
    12054: 'Thẻ ngân hàng hệ thống đã tồn tại',
    12055: 'Hình ảnh trang web đang được sử dụng',
    14000: 'Tên đăng nhập đã tồn tại',
    14001: 'Số điện thoại đã tồn tại',
    14002: 'Email đã tồn tại',
    14003: 'Mã liên kết đối tác không tồn tại',
    14004: 'Mã liên kết đối tác không thuộc về trang web này',
    14005: 'Chi phí liên kết phải nhỏ hơn chi phí liên kết cha:',
    14006: 'Thu nhập liên kết phải nhỏ hơn thu nhập liên kết cha',
    14007: 'Trạng thái đăng ký liên kết vẫn chưa hoàn thành',
    14008: 'Chi phí liên kết phải lớn hơn chi phí liên kết cha:',
    14009: 'Thu nhập liên kết phải lớn hơn thu nhập liên kết cha',
    14011: 'Đối tác đã tồn tại',
    14012: 'Chỉ có thể tạo ra liên kết cấp thấp hơn',
    14013: 'Không thể đặt cấp thấp hơn vì có đối tác hạng dưới',
    14014: 'Số điện thoại không chính xác / Số điện thoại không đúng định dạng',
    14100: 'Không có thành viên hạ lớp nào dưới đây',
    14101: 'Không có quyền thực hiện tác vụ này đối với liên kết này',
    14110: 'Cùng tên nền tảng và trang web đã tồn tại',
    14111: "Trước khi xóa, trạng thái phải là 'CLOSE' ít nhất một ngày",
    14112: 'Trạng thái của nền tảng trang web này đang đóng cửa trong một ngày, vui lòng xóa sau một ngày',
    14120: 'Cùng tên mức độ tài chính, tiền tệ, trang web và thiết bị đã tồn tại',
    14121: 'Số tiền rút tối thiểu không thể nhỏ hơn 0',
    14122: 'Số tiền rút tối đa không thể nhỏ hơn 0',
    14123: 'Số lần rút tối đa không thể nhỏ hơn 0',
    14124: 'Số tiền rút tối đa hàng ngày không thể nhỏ hơn 0',
    14125: 'Số tiền rút tối thiểu không thể nhỏ hơn số tiền rút tối đa',
    14126: 'Số tiền rút tối đa không thể nhỏ hơn số tiền rút tối thiểu',
    14127: 'Số tiền rút tối đa hàng ngày không thể nhỏ hơn số tiền rút tối đa',
    14130: 'Tài khoản nền tảng không tồn tại',
    14131: 'Tài khoản nền tảng đang được sử dụng trong nền tảng khác',
    15000: 'Tên VIP đã tồn tại trên trang web này',
    15001: 'VIP không tồn tại',
    15002: 'Không thể xóa VIP vì nó đang được sử dụng trong VIP hiện tại',
    15003: 'Đã có quy tắc giảm giá VIP cho cấp độ VIP và loại trò chơi đã cho',
    15004: 'Quy tắc giảm giá VIP không tồn tại',
    15005: 'Giảm giá VIP đã được phân phối',
    15008: 'Đã có quy tắc giảm giá hội viên cho tên đăng nhập và loại trò chơi đã cho',
    15009: 'Quy tắc giảm giá hội viên không tồn tại',
    15010: 'Tên cấp độ tài chính đã tồn tại trên trang web này',
    15012: 'Cấp độ tài chính không tồn tại',
    15020: 'Bản ghi gửi tiền không tồn tại',
    15021: 'Cùng tên mức độ tài chính, loại thanh toán, trang web và phương thức đã tồn tại',
    15030: 'Không thể xử lý dữ liệu JSON',
    15040: 'Tên hiển thị rút tiền đã tồn tại',
    15041: 'Tên hiển thị rút tiền không tồn tại',
    15050: 'Ngân hàng đã tồn tại trong khoản thanh toán',
    15051: 'Mã đã tồn tại trong khoản thanh toán đã cho',
    15060: 'ID nền tảng rút tiền không tồn tại',
    15061: 'Ngân hàng đang được sử dụng trong nền tảng khác',
    15062: 'Mã đang được sử dụng trong nền tảng khác',
    15063: 'Bản ghi rút tiền không tồn tại',
    15064: 'Nền tảng rút tiền không tồn tại',
    15065: 'Thẻ rút tiền không tồn tại',
    15066: 'Tên ngân hàng rút tiền đã tồn tại',
    16000: 'Yêu cầu một số có 3 chữ số',
    16001: 'Chỉ số phải là số có 3 chữ số',
    16002: 'Kết quả xổ số đã được công bố vào ngày đã chọn',
    16010: 'Tên đội tiếng Anh đã tồn tại',
    16011: 'Đội không tồn tại',
    16012: 'Không thể tìm thấy huy chương',
    17010: 'Bản ghi bỏ phiếu không tồn tại',
    17011: 'Bản ghi bỏ phiếu đã được hủy',
    18000: 'Tên miền đã tồn tại',
    18001: 'Miền liên kết không tồn tại',
    18002: 'Facebook Pixel does not exist',
    18003: 'This affiliate already set facebook pixel',
    19000: 'Thẻ ngân hàng đã được đưa vào danh sách đen',
    19002: 'Bóng bắt chéo màu đỏ',
    19003: 'Khoảng thời gian làm mới bị chồng chéo',
    19004: 'Chữ số cuối cùng trùng lặp',
    19101: 'Tiêu đề quảng cáo đã tồn tại',
    19102: 'Không thể tìm thấy ID quảng cáo',
    19103: 'Quảng cáo cho trang web này đã được bật. Vui lòng tắt quảng cáo hiện có',
    20000: 'Cài đặt SMS đã tồn tại trên trang web này',
    20100: 'Cài đặt email đã tồn tại trên trang web này',
    20201: 'Loại không hợp lệ',
    20202: "Vui lòng nhập '正常' hoặc '冻结'",
    20203: 'Lỗi định dạng ngày sinh (yyyy-MM-dd)',
    20204: 'Không thể tìm thấy mức độ nguy hiểm. Vui lòng nhập mức độ nguy hiểm chính xác (ví dụ: 1)',
    20205: 'Không thể tìm thấy mức độ tài chính. Vui lòng nhập mức độ tài chính chính xác (ví dụ: 1)',
    20206: "Vui lòng nhập '测试' / '普通' / '三方' / '代理'",
    20400: 'Bản ghi lăn bánh không tồn tại',
    21000: 'Trò chơi không tồn tại',
    21100: 'Thành viên này đã đạt đến giới hạn vé tối đa',
    21101: 'Không thể tìm thấy bánh xe nhiều lần cho trang web này',
    22001: 'Cấp độ VIP của thành viên đã đạt đến khoản thưởng tối đa $',
    21005: 'Ứng dụng đã tồn tại với cùng một trang web, hệ điều hành, loại ứng dụng và loại APK',
    21006: 'Tệp và hệ điều hành đã chọn không phù hợp',
    30000: 'Cập nhật trạng thái giao dịch PG không thành công',
    24003: 'Kết quả đã được xử lý trước đó',
    27000: 'Không đủ lượng chìa khóa',
    50002: 'Số tiền điều chỉnh tối đa đã vượt quá'
  },
}
