import { state } from './state'
import { UserMutationTypes } from './mutation-types'
import { UserActionTypes } from './action-types'
import { removeToken } from '@/utils/cookies'
import { resetRouter } from '@/router'
import {
  indLoginRequest,
  pakLoginRequest,
  loginRequest,
  updatePasswordRequest,
  userInfoRequest
} from "../../../api/user";
import { ElMessage } from "element-plus";
import i18n from "../../../i18n/index";
import { getSiteTitle } from "../../../utils/site";

export const actions = {
  async [UserActionTypes.ACTION_LOGIN]({ commit }, userInfo) {
    let { userName, password, googleCode } = userInfo
    userName = userName.trim()
    const currentHost = window.location.host
    if (currentHost === process.env.VUE_APP_IND_HOST || currentHost === process.env.VUE_APP_IW2_HOST) {
      const { data: token } = await indLoginRequest({ userName: userName, password, googleCode: googleCode });
      commit(UserMutationTypes.SET_TOKEN, token);
    } else if (currentHost === process.env.VUE_APP_PAK_HOST || currentHost === process.env.VUE_APP_PK2_HOST || currentHost === process.env.VUE_APP_BGD_HOST) {
      const { data: token } = await pakLoginRequest({ userName: userName, password, googleCode: googleCode });
      commit(UserMutationTypes.SET_TOKEN, token);
    } else {
      const { data: token } = await loginRequest({
        userName: userName,
        password,
        googleCode: googleCode,
      })
      commit(UserMutationTypes.SET_TOKEN, token)
    }
  },
  [UserActionTypes.ACTION_RESET_TOKEN]({ commit }) {
    removeToken()
    commit(UserMutationTypes.SET_TOKEN, '')
  },

  [UserActionTypes.ACTION_CHANGE_SITE_ID]({ commit }, siteInfo) {
    commit(UserMutationTypes.SET_SITE_ID, siteInfo)
  },

  [UserActionTypes.ACTION_UPDATE_TOKEN]({ commit }, token) {
    commit(UserMutationTypes.SET_TOKEN, token)
  },

  [UserActionTypes.ACTION_REFRESH_NOTIFICATIONS]({ commit }, notificationArr) {
    commit(UserMutationTypes.SET_NOTIFICATIONS, notificationArr)
  },

  async [UserActionTypes.ACTION_GET_USER_INFO]({ commit }) {
    if (state.token === '') {
      throw Error('token is undefined!')
    }
    const { data: loginInfo } = await userInfoRequest();
    document.title = getSiteTitle(loginInfo.siteId);
    commit(UserMutationTypes.SET_LOGIN_USER, loginInfo);
  },

  async [UserActionTypes.ACTION_LOGOUT]({ dispatch }) {
    // await logoutRequest();
    await dispatch(UserActionTypes.ACTION_RESET_TOKEN, undefined)
    sessionStorage.removeItem('WITHDRAW')
    sessionStorage.removeItem('BEFORE_PAID')
    sessionStorage.removeItem('PAYMENT')
    sessionStorage.removeItem('baseApi')
    resetRouter()
  },

  async [UserActionTypes.ACTION_UPDATE_LOGIN](
    _ctx,
    updateInfo // seems like it is the only required one
  ) {
    await updatePasswordRequest({
      password: updateInfo.password,
      oldPassword: updateInfo.oldPassword,
    })
    ElMessage({
      message: i18n.global.t('message.changeSuccess'),
      type: 'success',
    })
  },
}
